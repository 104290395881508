<template>
  <v-card
    class="pa-2"
    flat
    color="transparent"
    style="position: sticky; top: 64px;"
  >
    <template v-if="isPaid">
      <v-card-title
        class="pb-1"
        style="font-family: 'Nunito Sans', serif !important;"
      >
        {{ $t('checkoutPrepaid') }}
      </v-card-title>

      <v-divider class="pb-3" />

      <div
        v-if="producerDataInvalid"
        class="text-left"
        style="color: var(--v-error-base); font-size: 12px !important; letter-spacing: normal;"
      >
        {{ $t('invalidBillingInfo') }}
      </div>

      <v-btn
        elevation="0"
        class="mt-2 text-break overflow-hidden"
        color="primary"
        style="width: 100%;"
        :disabled="loadingPayment || producerDataInvalid"
        :loading="loadingSubmit"
        @click="submit(false, false)"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        {{ $t('safeSubmission') }}
      </v-btn>

      <div
        class="pt-6 align-start justify-start d-flex"
      >
        <v-icon
          left
          small
          style="opacity: 0.6; transform: translateY(2px);"
        >
          mdi-information-outline
        </v-icon>
        <span
          v-if="!tasting.tastingSettings.allowProductDataUpdate"
          class="text-body-2 text--secondary text-left align-start justify-start"
          v-html="$t('submissionPrepaidDeadline', { date: new Date(tasting.einreichenBis).toLocaleString() })"
        />
        <span
          v-if="tasting.tastingSettings.allowProductDataUpdate"
          class="text-body-2 text--secondary text-left align-start justify-start"
          v-html="$t('changeDataDeadline', { date: new Date(tasting.einreichenBis).toLocaleString() })"
        />
      </div>
    </template>

    <template v-else>
      <v-card-title
        class="pb-1"
        style="font-family: 'Nunito Sans', serif !important;"
      >
        {{ $t('checkoutPay') }}
      </v-card-title>
      <v-divider class="pb-3" />
      <checkout-prices-summary
        v-if="!tasting.tastingSettings.hasPurchasables"
        :ciders="ciders"
        :taxes="taxes"
        :currency="currency"
        :submission-fee="submissionFee"
        :price-per-cider="pricePerCider"
      />
      <checkout-prices-purchases
        v-if="tasting.tastingSettings.hasPurchasables"
        :price-including-taxes="includingTaxes"
        :tax-exempt="taxExempt"
        :taxes="taxes"
        :currency="currency"
        :price-per-cider="pricePerCider"
        :ciders="ciders"
        :products="products"
        :price-includes-ciders="included_"
      />
      <!--TODO price-includes-ciders-->
      <!--TODO price-includes-ciders -> workaround for award 24, please do better-->

      <v-form
        ref="form"
        v-model="valid"
      >
        <v-checkbox
          v-model="confirm"
          :rules="[v => !!v || $t('paymentHandling.infoHasToBeConfirmed')]"
          class="pb-0"
          :label="$t('checkoutPayConfirm')"
          required
        />
        <div
          v-if="producerDataInvalid"
          class="text-left"
          style="color: var(--v-error-base); font-size: 12px !important; letter-spacing: normal;"
        >
          {{ $t('invalidBillingInfo') }}
        </div>
      </v-form>

      <v-btn
        elevation="0"
        class="mt-2"
        color="primary"
        style="width: 100%;"
        :disabled="loadingSubmit"
        :loading="loadingPayment"
        @click="submit(true)"
      >
        <v-icon left>
          mdi-checkbox-marked-circle-outline
        </v-icon>
        {{ payNowText }}
      </v-btn>
<!--      todo-->
      <v-btn
        v-if="false"
        elevation="0"
        class="mt-2 text-break overflow-hidden mb-6"
        color="primary"
        style="width: 100%;"
        outlined
        :disabled="loadingPayment"
        :loading="loadingSubmit"
        @click="submit(false)"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        {{ isSmallScreen ? $t('savePayLater') : $t('saveSubmissionPayLater') }}
      </v-btn>

      <!--TODO(mo): store in backend ?-->
      <v-alert
        v-if="Date.now() < new Date(2025, 1 - 1, 16)"
        border="left"
        class="mt-4 mb-2 text-left"
        rounded
        text
        type="success"
        icon="mdi-sale"
      >
        <span class="text-body-2 text-left">
          {{ $t('timeBasedDiscount') }}
        </span>
      </v-alert>

      <div
        class="pt-2 align-start justify-start d-flex"
      >
        <v-icon
          left
          small
          style="opacity: 0.6; transform: translateY(2px);"
        >
          mdi-information-outline
        </v-icon>
        <span
          class="text-body-2 text--secondary text-left align-start justify-start"
          v-html="$t('submissionPaymentDeadline', { date: new Date(tasting.einreichenBis).toLocaleString() })"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
import util from "@/util";
import i18n from '@/i18n'
import CheckoutPricesSummary from "@/components/producer/checkout_prices_summary";
import CheckoutPricesPurchases from "@/components/producer/checkout_prices_purchases.vue";

export default {
  name: "ProducerCheckoutCard",
  components: {CheckoutPricesPurchases, CheckoutPricesSummary},
  props: {
    tasting: { type: Object, required: true, default: null },
    ciders: { type: Array, required: true, default: null },
    products: { type: Array, required: true, default: null },
    submissionFee: { type: Number, required: true, default: 0 },
    pricePerCider: { type: Number, required: true, default: 0 },
    taxes: { type: Number, required: true, default: 0 },
    currency: { type: String, required: true, default: "€" },
    includingTaxes: { type: Boolean, required: false, default: false },
    taxExempt: { type: String, required: true, default: "none" },
    loadingPayment: { type: Boolean, required: false, default: false },
    loadingSubmit: { type: Boolean, required: false, default: false },
    isPaid: { type: Boolean, required: false, default: true },
    producerDataInvalid: { type: Boolean, required: false, default: false },
    paymentOnline: { type: Boolean, required: false, default: true },
  },
  i18n:{
    messages:{
      de: {
        "submissionPaymentDeadline": `Die Menge der eingereichten Produkte und ihre Daten können bis zur Zahlung oder bis zum Ablauf der Frist am
        <br>
        <strong>{date}.</strong>
        <br>
 geändert werden. Der Gesamtpreis ergibt sich aus der Anmeldegebühr und den eingereichten Produkte zum Zeitpunkt der oben genannten Frist.
 `,
        "submissionPrepaidDeadline": `Die Menge der eingereichten Produkte und ihre Daten können bis zum Ablauf der Frist am
        <br>
        <strong>{date}</strong>
        <br>
 über diesen Prozess geändert werden.
 `,
        "changeDataDeadline": `Die Daten der eingereichten Produkte können bis zum Ablauf der Frist am
        <br>
        <strong>{date}.</strong>
        <br>
 geändert werden.`,
        "safeSubmission": "Einreichung speichern",
      },
      en: {
        "submissionPaymentDeadline": `The amount of submitted products and their data may be changed until payment or deadline on
        <br>
        <strong>{date}.</strong>
        <br>
The total price results from the Submission fee and the submitted products at the time of the deadline mentioned above.
 `,
        "submissionPrepaidDeadline": `The amount of submitted products and their data may be changed until the deadline on
        <br>
        <strong>{date}</strong>
via this process.
 `,
        "changeDataDeadline": `The data of the submitted products may be changed until the deadline on
        <br>
        <strong>{date}.</strong>
        <br>
 via this process.`,
        "safeSubmission": "Save submission",
      }
    }
  },
  data: () => ({
    valid: false,
    confirm: false,
  }),
  computed: {
    included_() {
      return this.products.length > 0 ? 1 : 0;
    },
    includedAwards() {
      return this.products.reduce((partialSum, product) => partialSum += product.includesAwards, 0);
    },
    payNowText() {
      if (this.paymentOnline) {
        return this.$t('submitPay');
      } else {
        return this.$t('confirmPurchase');
      }
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        default: return false
      }
    },
  },
  created() {
    this.$store.state.submitStore.includedAwards = this.included_;
  },
  methods: {
    async validate () {
      const isValid = await this.$refs.form.validate();
      return isValid;
    },
    async submit(payNow, validateForm = true) {
      if (validateForm) {
        await this.validate();
        if (!this.valid) return;
      }
      this.$emit('submit', payNow);
    }
  }
}
</script>

<style scoped>

</style>