import {JurorApiMixin} from "@/api/jurorApiMixin";
import {requiresLogin} from "@/api/apiBase";
import {createTableFromDatabase, Table} from "@/types/table";
import {RoleName, User} from "@/types/user";
import {components, MyCollections} from "@/api/types/my-collections";
import {createTastingFromDatabase, Tasting} from "@/types/tasting";
import {apiTyped} from "@/api/apiTyped";
import {createAdminTastingPurchase, TastingPurchase} from "@/types/tastingPurchase";
import {createInvoice, Invoice} from "@/types/Invoice";

class AdminApiMixin extends JurorApiMixin {

    @requiresLogin
    async adminGetTastings(): Promise<Tasting[] | undefined> {
        const resp = await this.sdk.items("tastings").readByQuery({
            fields: ["*"],
        });
        let allTastings = resp.data?.map((tasting: MyCollections["tastings"]) =>
            createTastingFromDatabase(tasting));
        if (allTastings === undefined) return undefined;
        allTastings = allTastings.sort((a, b) => {
            const date1 = a.datum !== null ? a.datum.getTime() : 0
            const date2 = b.datum !== null ? b.datum.getTime() : 0
            return date1 - date2;
        });
        // sort published first
        allTastings = allTastings.sort((a, b) => {
            const val1 = a.status === "published" ? 1 : 0;
            const val2 = b.status === "published" ? 1 : 0;
            return val2 - val1;
        });
        allTastings = allTastings.sort((t1, t2) => {
            if (t1.datum != null && t2.datum != null) return t2.datum.valueOf() - t1.datum.valueOf();
            if (t1.datum != null && t2.datum == null) return 1;
            if (t1.datum == null && t2.datum != null) return -1;
            return 0;
        });
        // allTastings = allTastings.sort((a, b) =>
        //     (a.date_created?.getTime() || a.datum?.getTime() || 1) - (b.date_created?.getTime() || b.datum?.getTime() || 1))
        return allTastings;
    }

    async migrateRevisions() {
        const resp = await this.sdk.items("eingereichte_ciders").readByQuery({
            fields: ['*'],
            limit: -1,
        });
        if (resp.data == null) return;
        for (const eingereichteCider of resp.data) {
            // get cider revision data:
            const ciderRevisions = await apiTyped.sdk.revisions.readByQuery({
                fields: ['*', 'activity.*'],
                filter: {
                    collection: {_eq: "ciders"},
                    item: {_eq: eingereichteCider.cider},
                    id: {_eq: eingereichteCider.cider_revision}
                },
                sort: ['-activity.timestamp'],
                limit: -1,  // TODO(pagination?): keep in mind if too many revisions occur later
            });
            if (ciderRevisions.data == null || ciderRevisions.data.length === 0 || ciderRevisions.data.length > 1) {
                console.log("Error for Revisions", ciderRevisions, eingereichteCider)
                continue;
            }
            const ciderRevision = ciderRevisions.data[0];
            const existing = await apiTyped.sdk.items("ciders").readByQuery({
                fields: ['*'],
                filter: {
                    eingereichte_ciders_revision: {id: { _eq: eingereichteCider.id } },
                    revision_of: {_eq: ciderRevision.data.id },
                }
            });
            if (existing?.data != null && existing.data?.length > 0) {
                console.warn("Skipping existing", ciderRevision, eingereichteCider);
                continue;
            }
            // get current cider data (translations and flight ids) and remove the flight
            const originalCider = await apiTyped.sdk.items("ciders").readOne(ciderRevision.data.id, {
                fields: [
                    'translations',
                    'flight_ids',
                    'images.*'
                ]
            });
            console.log("GOT ORIGIALN", originalCider);
            if (originalCider == null) {
                console.error("Error reading original Cider", ciderRevision);
                continue;
            }
            // const updatedOriginalCider = await apiTyped.sdk.items("ciders").updateOne(originalCider.id, {
            //     flight_ids: [],
            // });
            // if (updatedOriginalCider == null) {
            //     console.error("Error updating original cider:", originalCider, updatedOriginalCider);
            //     break;
            // }
            let newCiderImagesIds: number[] = [];
            // create new cider_images entry
            if (originalCider?.images != null && originalCider?.images.length > 0) {
                const newCiderImages = await apiTyped.sdk.items("ciders_files").createMany(
                    (originalCider?.images as any[]).map(
                        i => { return {ciders_id: i.ciders_id, directus_files_id: i.directus_files_id } })
                );
                if (newCiderImages == null) {
                    console.error("Error creating images", newCiderImages, originalCider, ciderRevision);
                    break;
                }
                newCiderImagesIds = newCiderImages.data?.map(d => d.id) as number[];
                console.log("Created new images:", newCiderImages, newCiderImagesIds);
                if (newCiderImagesIds.length === 0) break;
            }
            const newCiderRevision = await apiTyped.sdk.items("ciders").createOne({
                alkohol: ciderRevision.data.alkohol,
                bottle_content: ciderRevision.data.bottle_content,
                designation_of_origin: ciderRevision.data.designation_of_origin,
                display_id: ciderRevision.data.display_id,
                // eingereichte_ciders: [eingereichteCider.id as number],
                filtration_clarity: ciderRevision.data.filtration_clarity,
                flight_ids: originalCider.flight_ids,
                images: newCiderImagesIds,
                kategorie: ciderRevision.data.kategorie,
                land: ciderRevision.data.land,
                method: ciderRevision.data.method,
                name: ciderRevision.data.name,
                pressure: ciderRevision.data.pressure,
                produzent: ciderRevision.data.produzent,
                profil: ciderRevision.data.profil,
                profil_translations: ciderRevision.data.profil_translations,
                residual_sugar: ciderRevision.data.residual_sugar,
                residual_sugar_description: ciderRevision.data.residual_sugar_description,
                retail_price: ciderRevision.data.retail_price,
                sales_designation: ciderRevision.data.sales_designation,
                sort: ciderRevision.data.sort,
                status: ciderRevision.data.status,
                translations: originalCider.translations,
                typ: ciderRevision.data.typ,
                varieties: ciderRevision.data.varieties,
                user_created: ciderRevision.data.user_created,
                user_updated: ciderRevision.data.user_updated,
                revision_of: ciderRevision.data.id,
            });
            if (newCiderRevision == null) {
                console.error("Error creating revision", newCiderRevision, eingereichteCider, ciderRevision);
                continue;
            }
            console.log("Created new Cider Revision", newCiderRevision, "from revision:", ciderRevision, "and original:", originalCider);
            const resp = await this.sdk.items("eingereichte_ciders").updateOne(eingereichteCider.id as number, {
                product_revision: newCiderRevision.id,
            });
            console.log("Updated eingereichte ciders", resp);
        }
    }

    @requiresLogin
    async adminGetTablesAndFlights(tastingId: number): Promise<Table[] | null> {
        // same as jurorGetTablesAndFlights except no user id filter
        const resp = await this.sdk.items("tables").readByQuery({
            fields: [
                "*",
                "juror_ids.directus_users_id.*",
                "flight_ids.sort",
                "flight_ids.flights_id.id",
                "flight_ids.flights_id.cider_ids",
                "flight_ids.flights_id.name",
            ],
            filter: {
                tasting: {_eq: tastingId}
            },
        });
        if (resp?.data == null) return null;
        return resp.data.map(table => createTableFromDatabase(table));
    }

    @requiresLogin
    async adminGetAllUsers(tastingIds: number[] | null = null): Promise<User[]> {
        if (this.user?.role !== RoleName.Admin) return [];
        let myFilter = {};
        if (tastingIds !== null) myFilter = {current_tasting: {_in: tastingIds}}
        const resp = await this.sdk.items("directus_users").readByQuery({
            fields: ["*", "role.*"],
            filter: myFilter,
        });
        return resp.data?.map(user => new User(user as components["schemas"]["Users"])) || [];
    }

    @requiresLogin
    async createInvoice(
        tastingPurchaseId: number,
        invoiceStatus: 'draft' | 'published' | 'posted' | 'corrected' | 'archived',
        sendPerMail = false,
        invoiceNumber: number | null = null,
    ): Promise<Invoice | null> {
        const path = "invoices/create-invoice"
        const data = {
            tastingPurchaseId: tastingPurchaseId,
            invoiceStatus: invoiceStatus,
            sendMail: sendPerMail,
            invoiceNumber: invoiceNumber
        }
        const resp = await this.sdk.transport.post(path, data);
        console.log("Got send resp", resp)
        if (resp.raw != null && resp.status === 200 && resp.raw.hasOwnProperty("invoice")) {
            return createInvoice(resp.raw.invoice);
        }
        return null;
    }


    @requiresLogin
    async sendInvoice(tastingPurchaseId: number, invoiceId: number): Promise<string> {
        const path = "invoices/send-invoice"
        const data = {
            tastingPurchaseId: tastingPurchaseId,
            invoiceId: invoiceId
        }
        const resp = await this.sdk.transport.post(path, data);
        console.log("Got send resp", resp)
        return String(resp.raw);
    }

    @requiresLogin
    async adminGetPurchases(
        offset = 0,
        page = 1,
        limit = 50,
        filter: any,
        searchText: string | null = null,
        sortBy: string[] = ['judge.last_name']
    ): Promise<TastingPurchase[]> {
        const resp = await this.sdk.items("tasting_purchases").readByQuery({
            fields: ['*', 'invoices.*.*', 'user_created.*', 'purchased_products.*.*'],
            filter: {
                ...filter,
                ...(searchText != null && { "_or": [
                        { stripe_session_id: { _icontains: searchText } },
                        { reference_id: { _icontains: searchText } },
                        { company_name: { _icontains: searchText } },
                        { contact_person: { _icontains: searchText } },
                        { street: { _icontains: searchText } },
                        { province_state: { _icontains: searchText } },
                        { country: { _icontains: searchText } },
                        { postal_code: { _icontains: searchText } },
                        { email: { _icontains: searchText } },
                        { website: { _icontains: searchText } },
                        { vat: { _icontains: searchText } },
                        { payment_intent: { _icontains: searchText } },
                        { tax_exempt: { _icontains: searchText } },
                        { user_created: { first_name: { _icontains: searchText } } },
                        { user_created: { last_name: { _icontains: searchText } } },
                        { purchased_products: { tasting_product: { name: { _icontains: searchText } } } },
                        { purchased_products: { tasting_product: { description: { _icontains: searchText } } } },
                        { purchased_products: { tasting_product: { stripe_product: { _icontains: searchText } } } },
                        { purchased_products: { tasting_product_price: { name: { _icontains: searchText } } } },
                        { purchased_products: { tasting_product_price: { stripe_price_id: { _icontains: searchText } } } },
                    ] }),
            },
            limit: limit,
            offset: offset,
            page: page,
            // @ts-ignore
            sort: sortBy
        });
        console.log("Got purchase data", resp.data)
        if (resp.data == null) return [];
        return resp.data.map(d => createAdminTastingPurchase(d));
    }

    @requiresLogin
    async adminGetPurchaseById(purchaseId: number): Promise<TastingPurchase | null> {
        const resp = await this.sdk.items("tasting_purchases").readOne(
            purchaseId,
            { fields: ['*', 'invoices.*.*', 'user_created.*', 'purchased_products.*.*', 'purchased_products.tasting_product.translations.*'] }
        );
        console.log("Got single purchase data by id", resp)
        if (resp == null) return null;
        return createAdminTastingPurchase(resp);
    }

    @requiresLogin
    async getTableById(id: number) {
        if (this.user?.role !== RoleName.Admin) return undefined;
        const resp = await this.sdk.items("tables").readOne(id);
        if (resp === undefined) return resp
        return resp;
    }

    @requiresLogin
    async getCiderById(id: number) {
        if (this.user?.role !== RoleName.Admin) return undefined;
        const resp = await this.sdk.items("ciders").readOne(id);
        if (resp === undefined) return resp
        console.log("DB CIDER GOT",resp)
        return resp;
    }

    @requiresLogin
    async getKategorieById(id: number) {
        if (this.user?.role !== RoleName.Admin) return undefined;
        const resp = await this.sdk.items("kategorien").readOne(id);
        if (resp === undefined) return resp
        return resp;
    }

    @requiresLogin
    async addFileToFlightCider(
        flightCiderId: number,
        file: File | null,
        fileName: string,
        fileIcon: string,
        targetFolderId: string,
        existingFileId: string | null = null
    ): Promise<string | null> {
        if (file != null && existingFileId == null)
        {
            const form = new FormData();
            form.append('file', file);
            const uploadFileResp = await this.sdk.files.createOne(form);
            existingFileId = uploadFileResp?.id;
            if (existingFileId == null) return null;
            if (targetFolderId != null && targetFolderId !== "") {
                console.log("Updating target folder")
                await this.sdk.files.updateOne(existingFileId, { folder: targetFolderId });
            }
        }
        if (existingFileId == null) return null;
        // const existingFlightCider = await this.sdk.items("flights_ciders_files").readOne(flightCiderId, {
        //     fields: ['*']
        // });
        // if (existingFlightCider == null) return false;
        const insertResp = await this.sdk.items("flights_ciders_files").createOne({
            flights_ciders_id: flightCiderId,
            directus_files_id: existingFileId,
            name: fileName,
            icon: fileIcon,
        });
        if (insertResp == null) return null;
        else return existingFileId;
    }

    async getJudges(
        offset = 0,
        page = 1,
        limit = 50,
        filter: any,
        searchText: string | null = null,
        sortBy: string[] = ['judge.last_name']
    ) {
        console.log("filterSearch", searchText)
        const resp = await this.sdk.items("judges_data").readByQuery({
            fields: ['*', 'judge.*', 'judge.role.*', 'judge.avatar.*', 'country.*', 'preferred_language.*', 'languages.*.*'],
            filter: {
                ...filter,
                judge: {
                    role: { name: { _eq: "Juror" } },
                },
                ...(searchText != null && { "_or": [
                    { judge: { first_name: { _icontains: searchText } } },
                    { judge: { last_name: { _icontains: searchText } } },
                    { judge: { email: { _icontains: searchText } } },
                    { judge: { description: { _icontains: searchText } } },
                ] }),
            },
            limit: limit,
            offset: offset,
            page: page,
            // @ts-ignore
            sort: sortBy
        });
        console.log("Got juroren", resp)
        if (resp.data == null) return [];
        return resp.data.map((u: any) => new User(u.judge));
    }

}

export {AdminApiMixin}