<!--uses locales-->
<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
    style="max-width: 2000px;"
  >
    <PageNavigation
      :title-translation="$t('paymentHandling.submitProducts')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, 
                     {text: $t('paymentHandling.submitProducts'), to: '/producer/submit/summary'},
                     {text: $t('paymentHandling.submissionFinishedSuccessful'), disabled: true}]"
    />

    <producer-submit-stepper
      :current-step="submitTastingSteps.checkoutCompleted"
      disable-all
    />
    <step-navigation
      :next-disabled="true"
      :prev-disabled="false"
      :hide-next="true"
      :hide-prev="dataInPayingProcess"
      :prev-text="$t('paymentHandling.editSubmission').toUpperCase()"
      @prev="$router.push(`/producer/submit/summary`)"
      @next="$router.push(`/producer/home`)"
    />

    <v-container
      v-if="dataSelectedTasting != null && loaded === true"
      ref="successCard"
      class="pt-16 pb-16 ml-auto mr-auto"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="pb-3 ml-auto mr-auto pr-4 pl-4"
          width="420"
          outlined
          :elevation="hover ? 12 : 0"
          style="border-style: solid; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
        >
          <transition
            appear
            appear-active-class="bounce2"
          >
            <v-icon
              v-if="!dataInPayingProcess"
              ref="check"
              size="192"
              class="pb-3"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="dataInPayingProcess"
              ref="check"
              size="192"
              class="pb-3"
            >
              mdi-credit-card-check
            </v-icon>
          </transition>
          <v-card-subtitle
            v-if="dataInPayingProcess"
            class="pb-0 d-flex align-end justify-center"
            style="color: var(--v-primary-base); font-size: 18px !important;"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-checkbox-marked-circle-outline
            </v-icon>
            {{ $t('paymentHandling.paymentCompleted') }}
          </v-card-subtitle>

          <!--TODO(mo): Only for CiderWorld'25 ?-->
          <v-card-subtitle
            v-if="dataInPayingProcess"
            class="pb-0 d-flex align-end justify-center"
          >
            {{ $t('invoiceConfirmationPerEmail') }}
          </v-card-subtitle>

          <v-card-subtitle
            v-if="!noSubmittedProducts && !dataSubmitProductsWithoutData"
            class="pb-2 pt-1"
          >
            <v-badge
              :content="submittedCiders.length"
              inline
              color="primary"
              left
            >
              {{ $tc('product', submittedCiders.length) }} {{ $t('successfullySavedSubmission') }}
            </v-badge>
          </v-card-subtitle>
          <span
            class="ml-auto mr-auto text-center"
            style="opacity: 0.92;"
          >
            <span
              v-if="!dataInPayingProcess"
            >
              {{ selectedTasting.tastingSettings.submissionNeedsPayment
                ? $t('paymentHandling.completeUntilPrepaid')
                : tastingPurchase != null && isPaid
                  ? $t('paymentHandling.completeUntilClosed')
                  : $t('paymentHandling.completeUntilPay') }}
              <br>
              <strong>{{ new Date(dataSelectedTasting.einreichenBis).toLocaleString() }}</strong>
            </span>
            <span v-if="dataInPayingProcess" />
          </span>
          <v-card-actions
            class="pt-3 pb-2 pl-4 pr-4"
            :class="{'flex-column': isSmallScreen}"
          >
            <!--            <v-spacer v-if="inPayingProcess" />-->
            <v-btn
              rounded
              outlined
              class="mb-2 mr-1"
              style="text-transform: none !important;"
              @click="$router.push('/producer/home')"
            >
              <v-icon left>
                mdi-glass-wine
              </v-icon>
              {{ $t('paymentHandling.myProducts') }}
            </v-btn>
            <v-spacer />
            <div v-if="!dataInPayingProcess && !dataSelectedTasting.tastingSettings.submissionNeedsPayment && tastingPurchase == null">
              <v-btn
                rounded
                elevation="0"
                class="mb-2 ml-1"
                color="primary"
                style="text-transform: none !important;"
                @click="$router.push('/producer/submit/summary')"
              >
                <v-icon left>
                  mdi-checkbox-marked-circle-outline
                </v-icon>
                {{ $t('paymentHandling.payNow') }}
              </v-btn>
            </div>
            <div v-if="dataInPayingProcess || dataSelectedTasting.tastingSettings.submissionNeedsPayment || tastingPurchase != null">
              <v-btn
                rounded
                elevation="0"
                class="mb-2 ml-1"
                color="primary"
                style="text-transform: none !important;"
                @click="$router.push(`/producer/submitted/overview/${dataSelectedTasting.id}`)"
              >
                <v-icon left>
                  mdi-page-next
                </v-icon>
                {{ $t('paymentHandling.summary') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-container>
    <feedback-dialog
      v-model="showFeedback"
      :title="$t('Feedback.title')"
      origin="submit_process"
    />
    <v-divider />

    <div
      v-if="!noSubmittedProducts"
      class="d-flex text-left text-h2 font-weight-medium align-center mb-10 pt-8"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('paymentHandling.submittedProducts') }}
    </div>
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <div v-if="loaded && ciders != null && !submitProductsWithoutData && !noSubmittedProducts">
      <v-row no-gutters>
        <v-col
          v-for="cider in ciders"
          :key="cider.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          class="pa-3"
          style="cursor: pointer;"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <cider-card
              :cider="cider"
              :elevation="hover ? 10 : 1"
              :select-revision="dataSelectedTasting.id"
            />
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="loaded && submitProductsWithoutData && !noSubmittedProducts">
      <v-col
        v-for="index in selectedProductAmount"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          outlined
          class="mx-auto"
          style="position: relative;"
          width="240"
          height="320"
        >
          <v-card-title>
            Product {{ index }}
          </v-card-title>
          <v-icon
            class="mx-auto center-on-card"
            size="240"
          >
            mdi-bottle-wine
          </v-icon>
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="loaded"
    >
      <div
        class="d-flex text-left text-h2 font-weight-medium align-center mb-10 pt-8"
        style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
      >
        {{ $t('paymentHandling.tasting') }}
      </div>
      <div class="d-flex ml-auto mr-auto justify-center">
        <tasting-card
          :tasting="dataSelectedTasting"
          :submitted-ciders="submittedCiders"
          :max-submission-count="submitCount(dataSelectedTasting)"
          show-more-info-btn
          style="max-width: 500px;"
          class="pa-3"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import {get, sync} from "vuex-pathify";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import StepNavigation from "@/components/producer/step_navigation";
import CiderCard from "@/components/cider_card";
import {apiTyped} from "@/api";
import party from "party-js";
import TastingCard from "@/components/producer/tasting_card";
import FeedbackDialog from "@/components/feedback_dialog";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import PageNavigation from "@/components/page_navigation";
import {SubmitTastingStep} from "@/types/submitTastingStep";

export default {
  name: "ProducerSubmitSuccess",
  components: {PageNavigation, FeedbackDialog, TastingCard, CiderCard, StepNavigation, ProducerSubmitStepper},
  mixins: [producer_submit_mixin],
  beforeRouteLeave(to, from, next) {
    if (!to.path.endsWith('/producer/submit/summary')) {
      this.$store.commit("submitStore/clear");
    }
    next();
  },
  data: () => ({
    loaded: false,
    ciders: null,
    created: false,
    showFeedback: true,
    submittedCiders: null,
    dataSelectedTasting: null,
    dataSelectedCiders: [],
    dataConfirmedCiderIds: [],
    dataInPayingProcess: false,
    dataProducerData: null,
    dataSelectedTastingProducts: [],
    dataSubmitProductsWithoutData: false,
  }),
  computed: {
    noSubmittedProducts() {
      return this.dataSelectedTastingProducts.some(p => p.excludesAward);
    },
    isPaid() {
      return this.tastingPurchase != null && this.tastingPurchase.status === 'paid';
    },
    submitTastingSteps() {
      return SubmitTastingStep
    },
    selectedTasting: sync('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    inPayingProcess: sync('submitStore/inPayingProcess'),
    producerData: sync('producerStore/producerData'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
  },
  async created() {
    const debug = false;  // true, if you want to e.g. hot reload this page (store is not cleared and no route push for a reload)

    if (this.selectedTasting == null || this.selectedCiders == null
      || (this.selectedCiders.length === 0
        && this.selectedProductAmount === 0
        && this.selectedTastingProducts.every(t => t.excludesAward === false))
    ) {
      if (!debug) await this.$router.push('/producer/submit/tastings');
    }
    this.created = true;
    this.dataSelectedTasting = this.selectedTasting;
    this.dataSelectedCiders = this.selectedCiders;
    this.dataSelectedTastingProducts = this.selectedTastingProducts;
    this.dataConfirmedCiderIds = this.confirmedCiderIds;
    this.dataInPayingProcess = this.inPayingProcess;
    this.dataProducerData = this.producerData;
    this.dataSubmitProductsWithoutData = this.submitProductsWithoutData;
    console.log("Got dataSelectedTasting", this.dataSelectedTasting)
    this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.dataSelectedTasting.id) || [];

    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      this.tastingPurchase = await apiTyped.producerGetTastingPurchaseById(this.tastingPurchaseId);
    }

    if (this.dataInPayingProcess) {
      // Clear store data if paid, so back is not possible
      if (!debug) this.$store.commit("submitStore/clear");
      // Update status
      const success = await apiTyped.updateSubmittedCidersStatus(this.submittedCiders, 'payment_success', '');
      this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.dataSelectedTasting.id);
    }
    if (this.dataSelectedCiders.length > 0) {
      this.ciders = await apiTyped.producerGetAllCiders(
        this.$store.state.producerStore.producerId,
        0, 1, -1,
        {id: {_in: this.dataSelectedCiders.map(c => c.id)}}
      );
    } else if (this.submitProductsWithoutData) {
      this.ciders = [...Array(this.selectedProductAmount)].map((x, index) => {
        return {
          id: index,
          ciderInfos: {
            id: index,
            name: "Product " + (Number(index) + 1),
          }
        }
      })
    }
    const tasting = await apiTyped.producerGetAvailableTastings(0, 1, 1, [this.dataSelectedTasting.id]);
    if (tasting.length > 0 && tasting[0] != null) {
      this.dataSelectedTasting = tasting[0];
    }
    this.loaded = true;
  },
  mounted() {  // TODO(mo): move to a one shot watcher for loaded variable
    if (this.selectedTasting != null && this.selectedCiders != null) {
      party.confetti(this.$refs.check.$el, {
        count: party.variation.range(50, 100),
        size: party.variation.range(0.6, 1.4),
        color: party.Color.fromHex('#606200'),
      });
    }
  }
}
</script>

<style scoped>
.bounce2 {
  animation: bounce2 0.5s ease infinite;
}
@keyframes bounce2 {
  0% {
    opacity: 0;
    transform: translateY(60px) scale(0.3);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    transform: translateY(0) scale(1);
  }
}
</style>