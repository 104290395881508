<template>
  <div>
    <v-card-title class="pb-5 text-break">
      {{ getTranslation(participationProduct.translations, 'name') }}
    </v-card-title>
    <v-card-subtitle class="pb-0">
      <span v-if="participationProduct.dependentProductIds.length > 0">
        {{ $t('from') }}
      </span>
      <span
        class="text--primary darken-2 font-weight-bold text-h5"
        v-html="participationProduct.defaultPrice.unitPrice"
      />
      <span class="text--primary darken-2 font-weight-bold pl-1">€</span>
      <span v-if="participationProduct.defaultPrice.priceSuffix != null">
        {{ $t(participationProduct.defaultPrice.priceSuffix) }}
      </span>
    </v-card-subtitle>
    <v-card-text class="text--secondary py-0 pb-1">
      <template v-if="available != null">
        <strong>{{ available }}</strong> {{ $t('available') }}
      </template>
      <template v-else>
        &nbsp;
      </template>
    </v-card-text>
    <v-expand-transition>
      <div v-show="showDetails">
        <template v-for="(feature, index) in participationProduct.features">
          <feature-bullet
            :key="'feature' + index"
            :feature="feature"
          />
        </template>
      </div>
    </v-expand-transition>
    <v-card-text
      :style="{'padding-bottom': participationProduct.dependentProductIds.length === 0 ? '8px' : '16px'}"
      v-html="getTranslation(participationProduct.translations, 'description')"
    />
  </div>
</template>

<script>
import FeatureBullet from "@/components/producer/submit/feature_bullet.vue";
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "ParticipationCard",
  components: {FeatureBullet},
  mixins: [translation_mixin],
  props: {
    participationProduct: { type: Object, required: true, default: null },
    featureColored: { type: Boolean, required: true, default: true },
    showDetails: { type: Boolean, required: true, default: true },
    available: { type: Number, required: false, default: null }
  },
}
</script>

<style scoped>
</style>