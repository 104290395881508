import {apiTyped} from "@/api/apiTyped";
import {createUserSettingsFromDatabase, User} from "@/types/user";
import {AuthResult, Directus} from "@directus/sdk";
import {components, MyCollections} from "@/api/types/my-collections";
import {continents, countries} from "@/util/countries";
import {createPartnerFromDb, Partner} from "@/types/partner";
import {LoginPageMessages} from "@/types/loginPageMessages";


export enum ErrorType {
    otp
}

const requiresLogin = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any) {
        if (!apiTyped.isLoggedIn()) {
            console.log("Trying to access a login required method, API is not logged in. Logging in...")
            const loginSuccess = await apiTyped.retryLogin();
            if (loginSuccess == null) {
                throw new Error("Trying to access a login required method, API is not logged in. Logging in...");
            }
        }
        return originalMethod.apply(this, args);
    };
}

class ApiBase {
    readonly frontendUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080/'
        : 'https://award.cider-world.com/';

    // readonly baseUrl = process.env.NODE_ENV === 'development'
    //     ? 'https://test.cider-world.com/'
    //     : 'https://admin.cider-world.com/';

    // readonly baseUrl= 'http://localhost:8055';  // local api development
    // readonly baseUrl= 'https://test.cider-world.com/';  // test api
    readonly baseUrl = 'https://admin.cider-world.com/';  // prod api

    readonly baseEmail: string = "@cider-world.com";
    readonly resetUrl: string = 'https://award.cider-world.com/newPassword'
    readonly inviteUrl: string = 'https://award.cider-world.com/invite'
    user: User | undefined = undefined;
    sdk: Directus<MyCollections>;
    private loggedIn = false;

    constructor() {
        const transport = {
            url: this.baseUrl,
            beforeRequest: async (config: any) => {
                await this.sdk.auth.refreshIfExpired();
                const token = this.sdk.storage.auth_token;
                const bearer = token
                    ? token.startsWith(`Bearer `)
                        ? String(this.sdk.storage.auth_token)
                        : `Bearer ${this.sdk.storage.auth_token}`
                    : '';
                // Log all requests
                console.debug(JSON.stringify({...config,
                    headers: {
                        Authorization: bearer,
                        ...config.headers,
                    }}) + ",");
                return {
                    ...config,
                    headers: {
                        Authorization: bearer,
                        ...config.headers,
                    },
                };
            },
        };

        this.sdk = new Directus<MyCollections>(this.baseUrl,
            {
                auth: { autoRefresh: true, mode: 'json' },
                transport: transport
            }
        );
    }

    static isEmail(email: string) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    isLoggedIn(): boolean {
        return this.loggedIn;
    }

    async retryLogin(): Promise<User | undefined> {
        return this.loginRefresh();
    }

    async loginRefresh(): Promise<User | undefined> {
        try {
            if (this.sdk.auth.token != null) {
                try {
                    await this.sdk.auth.refreshIfExpired();
                } catch (e) {
                    console.error("Error refreshing", e)
                    return undefined;
                }
                // const authResult = await this.sdk.auth.refresh();
                // if (authResult === false) {
                //     console.log("REFRESH undef")
                //     return undefined;
                // } else {
                    console.log("REFRESH after")
                    return await this.loginAfter();
                // }
            } else {
                console.log("REFRESH undef2")
                return undefined;
            }
        } catch (error) {
            console.log("Error logging in:", error);
            return undefined;
        }
    }

    async login(email: string, password: string, otp: string | null): Promise<User | undefined | ErrorType> {
        // if (this.isLoggedIn()) return this.user;
        const isError = (err: unknown): err is Error => err instanceof Error;
        try {
            if (!ApiBase.isEmail(email)) {
                email = email.concat(this.baseEmail);
            }
            let authResult: AuthResult;
            if (otp != null) {
                authResult = await this.sdk.auth.login({email: email, password: password, otp: otp});
            }
            else {
                authResult = await this.sdk.auth.login({email: email, password: password});
            }
            if (authResult.access_token === "") {
                return undefined;
            } else {
                return await this.loginAfter();
            }
        } catch (error) {
            if (isError(error)) {
                if (error.message.includes("otp")) {
                    return ErrorType.otp;
                } else {
                    console.error("Error logging in:", error);
                }
            }
        }
        return undefined;
    }

    async loginToken(token: string): Promise<boolean> {
        try {
            const authResult = await this.sdk.auth.static(token);
            return authResult;
        } catch (error) {
            console.log("Error logging in:", error);
            return false;
        }
    }

    async loginAfter(): Promise<User | undefined> {
        this.loggedIn = true;
        await this.getUser();
        // console.log("Logged in User:", this.user);
        return this.user;
    }

    async refresh(): Promise<User | undefined> {
        if (this.isLoggedIn()) {
            console.log("Refreshing API Token...")
            // await this.sdk.auth.refresh();
            // await this.getUser();
            return this.user;
        } else {
            return this.user;
        }
    }

    async logout(): Promise<boolean> {
        try {
            await this.sdk.auth.logout()
            this.user = undefined;
            this.loggedIn = false;
            // await this.sdk.auth.refresh()
            return true;
        } catch (error) {
            console.log("Error logging out", error);
            return false;
        }
    }

    async resetPasswort(username: string) {
        await this.sdk.auth.password.request(username, this.resetUrl);
    }

    @requiresLogin
    async getUser() {
        const resp = await this.sdk.users.me.read({ fields: '*.*' });
        this.user = new User(<components["schemas"]["Users"]>resp);
        try {
            let userSettings = null;
            try {
                userSettings = await this.sdk.items("user_settings").readOne(this.user.id as string);
            } catch (e) {
                if (userSettings == null) {
                    console.log("CREATING SETTINGS USER")
                    userSettings = await this.createUserSettings();
                }
            }
            this.user.userSettings = createUserSettingsFromDatabase(userSettings as MyCollections["user_settings"]);
        }
        catch (error) {
            console.error(error)
        }
    }

    @requiresLogin
    async createUserSettings(): Promise<MyCollections["user_settings"]> {
        const resp = await this.sdk.items("user_settings").createOne( {
            user_id: this.user?.id,
            tour_table_complete: false,
            tour_rating_complete: false,
            tour_summary_complete: false,
        });
        return resp as MyCollections["user_settings"];
    }

    async acceptInvite(token: string, newPassword: string): Promise<boolean> {
        try {
            await this.sdk.users.invites.accept(token, newPassword);
            return true;
        } catch (e) {
            console.log("Error setting new Password", e);
            return false;
        }
    }

    async setNewPassword(token: string, newPassword: string): Promise<boolean> {
        try {
            await this.sdk.auth.password.reset(token, newPassword);
            return true;
        } catch (e) {
            console.log("Error setting new Password", e);
            return false;
        }
    }

    async getLanguages(): Promise<MyCollections["languages"][]> {
        const resp = await this.sdk.items("languages").readByQuery({
            fields: '*',
            filter: {
                enabled: {_eq: true },
            },
        });
        return resp?.data as Array<MyCollections["languages"]>;
    }

    async publicGetPartners(): Promise<Partner[] | null> {
        const resp = await this.sdk.items("partners").readByQuery({
            fields: [
                '*',
            ],
        });
        return resp?.data?.map(p => createPartnerFromDb(p)) || null;
    }

    async addCountries() {
        for (const country of countries) {
            const existingCountry = await this.sdk.items("countries").readByQuery({
                fields: ['id', 'code'],
                filter: {
                    code: { _eq: country.code },
                }
            });
            if (existingCountry.data != null && existingCountry.data.length > 0) {
                console.log("Skipping", country)
                continue;
            }
            // @ts-ignore
            const continentCode = continents[country.code];
            console.log("Adding country", country, continentCode);
            await this.sdk.items("countries").createOne({
                code: country.code.toLowerCase(),
                name: country.name,
                kontinent: continentCode,
            })
        }
    }

    async getLoginPageMessages() {
        const resp = await this.sdk.items("login_page_messages").readByQuery({
            fields: '*',
        });
        console.log("GOTtt", resp.data)
        if (resp == null || resp.data == null) return null;
        const data = resp.data as MyCollections["login_page_messages"];
        return <LoginPageMessages>{
            infosEnable: data.infos_enable || false,
            infosTitle: data.infos_title || "",
            infosMessage: data.infos_message || "",
            maintenanceEnable: data.maintenance_enable || false,
            maintenanceTitle: data.maintenance_title || "",
            maintenanceMessage: data.maintenance_message || "",
        }
    }
}

export { ApiBase, requiresLogin }