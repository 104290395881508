export default {
    methods: {
        getTranslation(translationMessages, key, lang = null, defaultText = "translation error") {
            if (lang == null) lang = this.$store.getters['usersStore/getUserLanguage']();
            if (translationMessages == null || translationMessages === {}) return "no translations";
            if (!translationMessages.hasOwnProperty(lang)) {
                lang = lang.split("-")[0]  // fallback for e.g. "de-DE" instead of only "de"
            }
            if (translationMessages.hasOwnProperty(lang) && translationMessages[lang].hasOwnProperty(key)) {
                return translationMessages[lang][key];
            } else {
                try {
                    return translationMessages['de-DE'][key];
                } catch {
                    try {
                        return translationMessages[Object.keys(translationMessages)[0]][key];
                    } catch {
                        return defaultText
                    }
                }
            }
        },
        getTranslationFromRepeater(repeater, prefix, lang = null, defaultText = "translation error") {
            // gets the translation from a repeater field. The fields have a prefix, like "text_" and then the lang code
            if (lang == null) lang = this.$store.getters['usersStore/getUserLanguage']();
            lang = lang.split("-")[0];
            if (repeater == null || repeater === {}) return "no translations";
            const key = prefix + lang;
            if (repeater.hasOwnProperty(key)) {
                return repeater[key];
            } else {
                try {
                    return repeater[prefix + 'de'];
                } catch {
                    return defaultText
                }
            }
        }
    },
}