export interface TastingProductPrice {
    id: number;
    unitPrice: number;
    currency: string;
    pricePrefix: string | null;
    priceSuffix: string | null;
}

export function createTastingProductPrice(tastingProductPrice: any) {
    return <TastingProductPrice>{
        id: tastingProductPrice.id,
        unitPrice: tastingProductPrice.unit_price,
        currency: tastingProductPrice.currency,
        pricePrefix: tastingProductPrice.price_prefix,
        priceSuffix: tastingProductPrice.price_suffix,
    }
}