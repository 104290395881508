<!--uses locales-->
<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('paymentHandling.submitProducts')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, {text: $t('paymentHandling.submitProducts'), disabled: true}]"
    />

    <producer-submit-stepper
      :current-step="submitTastingSteps.checkoutSummary"
    />
    <step-navigation
      :next-disabled="true"
      :prev-disabled="false"
      :hide-next="true"
      class="pb-3"
      @prev="$router.push(`/producer/submit/confirmData`)"
      @next="$router.push(`/producer/submit/summary`)"
    />
    <div
      v-if="loaded"
      class="ml-auto mr-auto pt-4"
      style="max-width: 1200px;"
    >
      <v-row
        class="d-flex"
      >
        <v-col
          cols="12"
          xs="12"
          sm="7"
          md="8"
          class="d-flex justify-center align-self-start pa-0"
        >
          <v-row
            class="d-flex pa-0"
            style="max-width: 100%;"
          >
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              class="d-flex justify-center align-self-start align-center flex-column"
            >
              <div
                v-ripple
                class="d-flex pa-2 pl-3 mb-2 align-baseline text-left text--secondary text-h5 collapse-section-header"
                style="width: 100%; cursor: pointer;"
                @click="showPaymentCard = !showPaymentCard"
              >
                {{ $t('paymentHandling.paymentInfo') }}
                <v-btn
                  icon
                  class="ml-2 align-center"
                >
                  <v-icon :class="{rotate_180: showPaymentCard}">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
                <v-chip
                  v-if="producerDataValid"
                  color="transparent"
                  class="ml-auto mr-4"
                  style="min-width: 32px;"
                >
                  <v-icon class="ml-auto mr-auto">
                    mdi-check
                  </v-icon>
                </v-chip>
              </div>
              <v-expand-transition>
                <producer-data-card
                  v-show="showPaymentCard"
                  ref="producerDataCard"
                  v-model="producerData"
                  :countries="countries"
                  style="max-width: min(600px, 100%);"
                  @valid="setProducerDataValid"
                />
              </v-expand-transition>
            </v-col>

            <v-col
              v-if="selectedTasting.tastingSettings.hasPurchasables"
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              class="d-flex justify-self-start flex-column"
            >
              <div
                v-ripple
                class="d-flex pa-2 pl-3 mb-2 align-baseline text-left text--secondary text-h5 collapse-section-header"
                style="width: 100%; cursor: pointer;"
                @click="showPurchases = !showPurchases"
              >
                {{ selectedTastingProducts.length }} {{ $t('paymentHandling.productsSelected') }}
                <v-btn
                  icon
                  class="ml-2 align-center"
                >
                  <v-icon :class="{rotate_180: showPurchases}">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </div>

              <v-expand-transition>
                <div v-show="showPurchases">
                  <div
                    class="flex-row d-flex align-start justify-start flex-wrap pa-0"
                  >
                    <v-row class="">
                      <v-col
                        v-for="product in selectedTastingProductsBooth"
                        :key="product.id"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        align-self="start"
                        class="d-flex justify-center align-self-start"
                      >
                        <v-card
                          :elevation="$vuetify.theme.dark ? 0 : 1"
                          :outlined="$vuetify.theme.dark"
                          style="width: 100%;"
                          class="text-left align-content-stretch align-stretch pt-2 pb-6"
                        >
                          <v-card-text
                            class="pb-0"
                            style="margin-bottom: -12px"
                          >
                            Stand
                          </v-card-text>
                          <booth-card
                            feature-colored
                            :booth-product="product"
                            :show-details="true"
                            :available="product.availableAmount"
                          />
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        align-self="end"
                      >
                        <div
                          class="d-flex flex-column"
                          style="gap: 16px;"
                        >
                          <div
                            v-for="product in selectedTastingProductsExtra"
                            :key="product.productId"
                          >
                            <v-card
                              :elevation="$vuetify.theme.dark ? 0 : 1"
                              :outlined="$vuetify.theme.dark"
                              class="text-left pt-2 pb-6"
                            >
                              <extra-card
                                :extra-product="product"
                                :available="getAvailableAmount(product.productId)"
                              />
                            </v-card>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>

            <v-col
              v-if="!$store.getters['submitStore/awardExcluded']"
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              class="d-flex justify-self-start flex-column"
            >
              <div
                v-ripple
                class="d-flex pa-2 pl-3 mb-2 align-baseline text-left text--secondary text-h5 collapse-section-header"
                style="width: 100%; cursor: pointer;"
                @click="showSubmittedProduct = !showSubmittedProduct"
              >
                {{ submitProductsWithoutData ? selectedProductAmount : ciders != null ? ciders.length : '' }} {{ $t('paymentHandling.submittedProducts') }}
                <v-btn
                  icon
                  class="ml-2 align-center"
                >
                  <v-icon :class="{rotate_180: showSubmittedProduct}">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </div>

              <v-expand-transition>
                <div
                  v-show="showSubmittedProduct
                    && submitProductsWithoutData === false
                    && !$store.getters['submitStore/awardExcluded']
                  "
                >
                  <div
                    class="flex-row d-flex align-center justify-center flex-wrap pa-0"
                  >
                    <v-row class="justify-center">
                      <v-col
                        v-for="cider in ciders"
                        :key="cider.id"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        align-self="center"
                        class="d-flex justify-center align-self-center"
                      >
                        <cider-card
                          style="width: 100%; max-width: 400px;"
                          class="d-flex align-center justify-center pb-4"
                          :select-revision="selectedTasting.id"
                          disable-revisions
                          :cider="cider"
                          outlined
                          hide-stats
                          :elevation="0"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="pt-2 pb-3 align-start justify-start d-flex ml-auto mr-auto"
                  >
                    <v-icon
                      left
                      small
                      style="opacity: 0.6; transform: translateY(2px);"
                    >
                      mdi-information-outline
                    </v-icon>
                    <span
                      class="text-body-2 text--secondary text-left align-start justify-start"
                    >
                      {{ $t('paymentHandling.forProductTastingCreated') }}
                      <!--<strong>{{ ciders[0].getRevisionName(new Date(Date.now()), selectedTasting.abbreviation, false, false) }})</strong>-->
                      <strong>{{ selectedTasting.abbreviation }})</strong>
                      <br>
                      <template v-if="selectedTasting.tastingSettings.allowProductDataUpdate">
                        {{ $t('paymentHandling.dataLockedAfterSubmissionClosed',
                              {closingDate: new Date(selectedTasting.einreichenBis).toLocaleString()}) }}
                      </template>
                      <template v-else>
                        {{ $t('paymentHandling.dataLockedAfterFinishedOrSubmissionClosed',
                              {closingDate: new Date(selectedTasting.einreichenBis).toLocaleString()}) }}
                      </template>
                    </span>
                  </div>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-show="showSubmittedProduct && submitProductsWithoutData === true">
                  <v-row>
                    <v-col
                      v-for="index in selectedProductAmount"
                      :key="index"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-card
                        outlined
                        class="mx-auto"
                        style="position: relative;"
                        width="240"
                        height="300"
                      >
                        <v-card-title>
                          Product {{ index }}
                        </v-card-title>
                        <v-icon
                          class="mx-auto center-on-card pt-4"
                          size="240"
                        >
                          mdi-bottle-wine
                        </v-icon>
                      </v-card>
                    </v-col>
                  </v-row>
                  <div
                    class="pt-2 pb-3 align-start justify-start d-flex ml-auto mr-auto"
                  >
                    <v-icon
                      left
                      small
                      style="opacity: 0.6; transform: translateY(2px);"
                    >
                      mdi-information-outline
                    </v-icon>
                    <span
                      class="text-body-2 text--secondary text-left align-start justify-start"
                    >
                      {{ $t('placeholderProducts') }}
                      <br>
                      {{ $t('paymentHandling.dataLockedAfterSubmissionClosed',
                            {closingDate: new Date(selectedTasting.einreichenBis).toLocaleString()}) }}
                    </span>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="5"
          md="4"
          class="d-flex align-start flex-column"
        >
          <producer-checkout-card
            :tasting="selectedTasting"
            :ciders="productsToSubmit"
            :products="selectedTastingProducts"
            :price-per-cider="selectedTasting.tastingPrices.pricePerSubmission"
            :submission-fee="selectedTasting.tastingPrices.submissionFee"
            :taxes="selectedTasting.tastingPrices.taxes"
            :producer-data-invalid="!producerDataValid"
            :loading-payment="loadingPayment"
            :loading-submit="loadingSubmission"
            :currency="selectedTasting.tastingPrices.currency"
            :including-taxes="priceIncludingTaxesComputed"
            :is-paid="isPaid"
            :tax-exempt="taxExempt"
            :payment-online="false"
            @submit="submitCiders"
          />
        </v-col>
      </v-row>
    </div>
    <step-navigation
      v-if="loaded"
      :next-disabled="true"
      :prev-disabled="false"
      :hide-next="true"
      class="pt-6"
      @prev="$router.push(`/producer/submit/confirmData`)"
      @next="$router.push(`/producer/submit/summary`)"
    />
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />

    <v-snackbar
      v-model="error"
      color="error"
      rounded="xl"
      :multi-line="false"
      bottom
      style="opacity: 0.95;"
    >
      <v-card-title class="d-flex pt-2 pb-2 text-center justify-center">
        <v-icon left>
          mdi-alert-circle
        </v-icon>
        {{ $t('errorSubmit') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-spacer />
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          class="ml-auto ml-4"
          @click="reloadPage"
        >
          {{ $t('paymentHandling.reloadPage') }}
        </v-btn>
      </template>
    </v-snackbar>
    <Confirm_dialog ref="confirm" />
    <v-dialog
      v-model="errorDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('errorOccurred') }}
        </v-card-title>

        <v-card-text class="text-left">
          {{ $t('pleaseContactUs') }}
        </v-card-text>
        <v-card-text style="color: red;">
          {{ errorText }}
        </v-card-text>

        <v-btn
          color="error"
          text
          outlined
          class="mb-2"
        >
          <a
            style="color: unset !important; text-decoration: none;"
            :href="'mailto:info@cider-world.com?subject=Error&body=' + errorTextEncoded"
            class="ml-1"
          >
            {{ $t('sendErrorMail') }}
          </a>
        </v-btn>
        <v-divider class="mx-16" />
        <v-card-subtitle class="text--secondary pt-2">
          info@cider-world.com
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error darken-1"
            text
            @click="errorDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="expectedErrorDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('expectedErrorOccurred') }}
        </v-card-title>
        <v-card-text class="text-left">
          {{ expectedErrorText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error darken-1"
            text
            @click="expectedErrorDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {get, sync} from "vuex-pathify";
import Confirm_dialog from "@/components/confirm_dialog";
import {apiTyped} from "@/api";
import StepNavigation from "@/components/producer/step_navigation";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import ProducerDataCard from "@/components/producer/producer_data_card";
import util from "@/util";
import ProducerCheckoutCard from "@/components/producer/producer_checkout_card";
import CiderCard from "@/components/cider_card";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import PageNavigation from "@/components/page_navigation";
import {SubmitTastingStep} from "@/types/submitTastingStep";
import ExtraCard from "@/components/producer/submit/award23/extra_card.vue";
import BoothCard from "@/components/producer/submit/booth_card.vue";

export default {
  name: "ProducerSubmitSummary",
  components: {
    BoothCard,
    ExtraCard,
    PageNavigation,
    CiderCard, ProducerCheckoutCard, ProducerDataCard, ProducerSubmitStepper, StepNavigation, Confirm_dialog},
  mixins: [producer_submit_mixin],
  async beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave", to, from, next)
    if (this.matchesWhitelist(to.path) || this.routeGuardDisabled) next();
    const okayToLeave = await this.$refs.confirm.open(
      this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
      { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') }, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  data: () => ({
    expectedErrorDialog: false,
    expectedErrorText: 'None yet, all good :)',
    errorDialog: false,
    errorText: 'None yet :(',
    routeGuardDisabled: false,
    loaded: false,
    error: false,
    ciders: null,
    countries: [],
    loadingSubmission: false,
    loadingPayment: false,
    producerDataValid: true,
    showPaymentCard: true,
    showSubmittedProduct: true,
    showPurchases: true,
  }),
  i18n: {
    messages: {
      de: {
        'placeholderProducts': 'Die hier angezeigten Produkte werden als Platzhalter eingereicht. Um am Event teilzunehmen müssen bis zur Deadline die Produktdaten unter "Meine Events" nachgereicht werden.',
        'invalidEuVat': "Ungültige EU-Vat Nummer. Bitte eine gültige eingeben oder Inhalt des Feldes löschen"
      },
      en: {
        'placeholderProducts': 'The products displayed here are submitted as placeholders. In order to take part in the event, the product data must be submitted under “My Events” by the deadline.',
        'invalidEuVat': "Invalid EU-VAT. Please enter a valid number or clear the content of the field"
      }
    }
  },
  computed: {
    taxExempt() {
      // subscribe to these two fields for updates
      const country = this.$store.state.producerStore.producerData.country;
      const vat = this.$store.state.producerStore.producerData.vat;
      if (!vat || !country) {
        return 'none';
      }
      return this.$store.getters["submitStore/taxExemption"];
    },
    priceIncludingTaxesComputed() {
      const settings = this.selectedTasting.tastingPrices.priceIncludesTaxes;
      if (this.selectedTasting.tastingSettings.hasPurchasables) {
        return !(this.taxExempt === 'exempt' || this.taxExempt === 'reverse');
      } else {
        return settings;
      }
    },
    isPaid() {
      return this.selectedTasting.tastingSettings.submissionNeedsPayment
        || (this.tastingPurchase != null && this.tastingPurchase.status === "paid")
    },
    productsToSubmit() {
      const b = this.submitProductsWithoutData;
      if (this.submitProductsWithoutData === false) {
        return this.ciders;
      }
      else {
        return [...Array(this.selectedProductAmount)].map((x, index) => {
          return {
            id: index,
            ciderInfos: {
              id: index,
              name: "Product " + (Number(index) + 1),
            }
          }
        })
      }
    },
    submitTastingSteps() {
      return SubmitTastingStep
    },
    errorTextEncoded() {
      return encodeURIComponent(this.errorText);
    },
    selectedTasting: sync('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    producerData: sync('producerStore/producerData'),
    isMdOrSmaller () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        default: return false
      }
    },
    selectedTastingProductsExtra() {
      return this.selectedTastingProducts.filter(p => p.type === 'extra');
    },
    selectedTastingProductsBooth() {
      return this.selectedTastingProducts.filter(p => p.type === 'booth');
    },
  },
  async created() {
    this.$store.set('submitStore/inPayingProcess', false);
    if (this.selectedTasting == null || this.selectedCiders == null
      || (this.selectedCiders.length === 0
          && this.selectedProductAmount === 0
          && this.selectedTastingProducts.every(t => t.excludesAward === false))
    ) {
      console.log("Tasting is null")
      await this.$router.push('/producer/submit/tastings');
    }
    this.tastings = await apiTyped.producerGetAvailableTastings();
    this.selectedTasting = this.tastings.find(t => t.id === this.selectedTasting.id);  // update tasting data
    const submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
    console.log("Got submitted", submittedCiders)
    if (!submittedCiders.every(c => c.status === 'draft' || c.status === 'unpaid')
      && !this.selectedTasting.tastingSettings.allowProductDataUpdate) {
      // tasting submitted ciders are not in draft or unpaid, redirect to tasting overview page
      await this.$router.push(`producer/submitted/overview/${this.selectedTasting.id}`);
    }

    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      this.tastingPurchase = await apiTyped.producerGetTastingPurchaseById(this.tastingPurchaseId);
      if (this.tastingPurchase != null) {
        this.purchasedAmount = this.tastingPurchase.submittedProductIds.length;
        // const purchasedProductIds = this.tastingPurchase.purchasedProducts.map(p => p.tastingProduct.id);
        // this.selectedTastingProducts = purchasedProductIds.map(p => { return { id: p } } )
      }
    }

    await this.$store.dispatch('producerStore/getProducerData');
    this.countries = await apiTyped.getAllCountries() || [];
    this.$store.state.submitStore.allCountries = this.countries;
    if (this.selectedCiders.length > 0) {
      this.ciders = await apiTyped.producerGetAllCiders(
        this.$store.state.producerStore.producerId,
        0, 1, -1,
        { id: {_in: this.selectedCiders.map(c => Number(c.id)) } }
      );
    } else {
      this.ciders = [];
    }

    console.log("this.ciders", this.ciders, this.selectedCiders)
    for (const cider of this.ciders) {
      console.log("Going through cider", cider)
      if (cider.editRevisionInfos == null) {
        // create editRevisionInfos, TODO maybe move to first step when loading existing eingereichte ciders!
        cider.editRevisionInfos = {
          kommentar: "",
          revisionId: -1,
          status: "draft",
        };
      }
      const ciderWasSubmitted = submittedCiders.find(s => s.cider === cider.id);
      if (ciderWasSubmitted != null) {
        console.log("ciderWasSubmitted", ciderWasSubmitted)
        // do not create a jahrgang preview, already submitted this cider
        cider.editRevisionInfos = {
          kommentar: ciderWasSubmitted.kommentar,
          revisionId: ciderWasSubmitted.productRevision,
          status: "draft",
        }
      } else {
        const infos = JSON.parse(JSON.stringify(cider.ciderInfos));
        const [e, rev] = cider.createPreviewJahrgang(infos, this.selectedTasting, cider.editRevisionInfos.kommentar);
        cider.submitted.unshift(e);
        cider.revisions.unshift(rev);
        console.log("CIDER REVS", e, rev)
      }
    }
    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      console.log("Getting this.selectedTasting.products")
      this.selectedTasting.products = await apiTyped.producerGetTastingProducts(this.selectedTasting.id);
      console.log("Got this.selectedTasting.products", this.selectedTasting.products)
      if (this.selectedTastingProducts != null && this.selectedTastingProducts.length !== 0) {  // update infos
        const selectedProductIds = this.selectedTastingProducts.map(p => p.id);
        this.selectedTastingProducts = this.selectedTasting.products.filter(p => selectedProductIds.includes(p.id));
        console.log("this.selectedTastingProducts", this.selectedTastingProducts)
        // if (selectedProductIds.length !== this.selectedTastingProducts.length) {
        //   console.error("Error while getting products for stored products",
        //     selectedProductIds, this.selectedTastingProducts);
        //   this.routeGuardDisabled = true;
        //   this.$store.commit("submitStore/clear");
        //   await this.$router.push("/producer/submit/tastings");
        // }
      }
    }
    console.log("Loaded")
    this.loaded = true;
  },
  methods: {
    getAvailableAmount(productId) {
      const product = this.selectedTastingProducts.find(p => p.id === productId);
      console.log("productproduct", product, productId)
      return product != null ? product.availableAmount : null;
    },
    setProducerDataValid(isValid) {
      this.producerDataValid = isValid;
    },
    async submitCiders(payNow) {
      try {
        if (payNow) this.loadingPayment = true;
        else this.loadingSubmission = true;
        const isProducerDataValid = await this.$refs.producerDataCard.validate();
        if (!isProducerDataValid) {
          this.producerDataValid = false;
          this.loadingSubmission = false;
          this.loadingPayment = false;
          return;
        }
        this.producerDataValid = true;
      } catch (e) {
        this.error = true;
        this.loadingSubmission = false;
        this.loadingPayment = false;
        console.log("Error while submitting:", e)
        return;
      }
      let submittedCiderIds;
      try {
        if (this.submitProductsWithoutData === false && !this.$store.getters["submitStore/awardExcluded"]) {
          const ciderRevisions = await apiTyped.producerGetAllCiders(
            this.$store.state.producerStore.producerId,
            0, 1, -1,
            { id: { _in: this.ciders.map(c => c.id) } }
          );
          if (ciderRevisions == null) {
            console.error("Error getting Revisions");
            this.error = true;
            return;
          }
          // this.ciders.forEach(
          //   c => c.editRevisionInfos.revisionId = ciderRevisions.find(r => r.ciderId === c.id)?.revisionId);
          // get existing submitted ciders for tasting
        }
        const submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
        if (submittedCiders == null) {
          console.error("Error getting Eingereichte Ciders");
          this.errorText = "Error getting Eingereichte Ciders";
          this.errorDialog = true;
          this.error = true;
          return;
        }
        // deleting un-submitted ciders, if already submitted for that tasting
        const ciderIds = this.submitProductsWithoutData ? [] : this.ciders.map(c => c.id);
        console.log("Got submitted Ciders", this.selectedTasting.id, submittedCiders, ciderIds)
        const unSubmittedCiders = submittedCiders.filter(
          submittedCider => !ciderIds.includes(submittedCider.productRevision));
        if (unSubmittedCiders.length > 0) {
          console.log("Deleting existing submissions", unSubmittedCiders)
          // todo(mo): when to skip deleting? only with tasting purchasables?
          // await apiTyped.deleteSubmittedCidersForTasting(unSubmittedCiders);
        }
        const addResult = await apiTyped.addSubmittedCidersForTasting(
          this.productsToSubmit,
          this.selectedTasting,
          this.$store.state.producerStore.producerId,
          this.submitProductsWithoutData,
          this.tastingPurchase != null ? this.tastingPurchase.id : null
        );
        submittedCiderIds = addResult.newItemIds;
        console.log("addResult", addResult)
        if (addResult.success == null) {
          console.error("Error adding Eingereichte Ciders", this.ciders, unSubmittedCiders, addResult);
          this.errorText = "Error adding Eingereichte Ciders";
          this.errorDialog = true;
          this.error = true;
          this.loadingSubmission = false;
          this.loadingPayment = false;
          return;
        }
      } catch (e) {
        console.log("Error submitting Ciders", e, this.ciders);
        this.errorText = `Error submitting Ciders ${e}`;
        this.errorDialog = true;
        this.error = true;
        this.loadingSubmission = false;
        this.loadingPayment = false;
        return;
      }
      if (this.error === false) {
        console.log("no error", payNow)
        if (payNow) {
          this.$store.set('submitStore/inPayingProcess', true);
          if (this.selectedTasting.tastingSettings.hasPurchasables) {
            const tastingProducts = this.selectedTastingProducts.map(
              p => { return {"id": p.id, "amount": p.amount || 1}});
            // TODO:
            if (!this.$store.getters["submitStore/awardExcluded"]) {
              // included award product
              if (this.selectedTastingProducts.length > 0) {  // TODO
                tastingProducts.push({"id": 9, "amount": 1});
              }
            }
            const offsetForAward24 = this.selectedTastingProducts.length === 0 ? 0 : 1;  // todo (one is included if not additional products are booked)
            if (this.submitProductsWithoutData && this.selectedProductAmount > 1) {
              tastingProducts.push({"id": 10, "amount": this.selectedProductAmount - offsetForAward24})
            } else if (this.selectedCiders.length > 1) {  // additional award products
              tastingProducts.push({"id": 10, "amount": this.selectedCiders.length - offsetForAward24})
            } else if (this.selectedTastingProducts.length === 0) {
              tastingProducts.push({"id": 10, "amount": 1});
            }
            try {
              if (false) {
                const sessionUrl = await apiTyped.createCheckoutSession(
                  this.selectedTasting.id,
                  tastingProducts,
                  submittedCiderIds,
                  false,
                  this.$store.getters["submitStore/totalPrice"],
                  this.$store.getters["submitStore/subTotalPrice"],
                  this.$store.getters["submitStore/taxesAmount"],
                );
                console.log("Got session url", sessionUrl);
                window.open(sessionUrl, "_self");
              }
              else {
                const resp = await apiTyped.createCheckoutSession(
                  this.selectedTasting.id,
                  tastingProducts,
                  submittedCiderIds,
                  false,
                  this.$store.getters["submitStore/totalPrice"],
                  this.$store.getters["submitStore/subTotalPrice"],
                  this.$store.getters["submitStore/taxesAmount"],
                );
                // if (resp.status !== "success") {
                //   throw new Error(resp.message);
                // }
                console.log("Got resp", resp);
                await this.$router.push(`/producer/submit/success`);
              }
            } catch (e) {
              console.error(e);
              console.log("msg:", e.message);
              if (e.message.toLowerCase().includes("invalid value for eu_vat")) {
                this.expectedErrorText = this.$t('invalidEuVat');
                this.expectedErrorDialog = true;
              } else {
                // TODO: insufficient amount
                this.errorText = e;
                this.errorDialog = true;
              }
              this.loadingSubmission = false;
              this.loadingPayment = false;
            }
          } else {
            // TODO insert links
            // const stripeLink1 = "https://buy.stripe.com/test_dR67vQbC2aUfcqAfYY";  // TEST LINK
            const stripeLink1 = "https://buy.stripe.com/8wM7uM4Mb6jn7sc144";
            const stripeLink2 = "https://buy.stripe.com/6oE7uM6Uj4bf5k46op";
            const stripeLink3 = "https://buy.stripe.com/00g4iA0vV5fj3bW8wy";
            let link;
            if (this.selectedCiders.length === 1) link = stripeLink1;
            if (this.selectedCiders.length === 2) link = stripeLink2;
            if (this.selectedCiders.length === 3) link = stripeLink3;
            window.open(link, "_self");
          }
        } else {
          this.$store.set('submitStore/inPayingProcess', false);
          await this.$router.push(`/producer/submit/success`);
        }
      }
    },
    reloadPage() {
      window.location.reload(true)
    },
  }
}
</script>

<style scoped>
.checkout-item-text {
  font-size: 14px !important;
}
.sticky-producer-info {
  position: sticky;
  top: 64px;
}

.collapse-section-header:hover {
  background-color: var(--v-divider-base);
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  border-radius: 99px;
}

.center-on-card {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}
</style>