import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";
import {createTastingProductPrice, TastingProductPrice} from "@/types/tastingProductPrice";
import {MyCollections} from "@/api/types/my-collections";

export interface TastingProduct {
    id: number;
    previewImage: string;
    name: string;
    availableAmount: number;
    isLimited: boolean;
    shortDescription: string;
    description: string;
    type: "booth" | "extra" | "booth_parent" | "product_tasting";
    stripeProduct: string;
    defaultPrice: TastingProductPrice | null;
    translations: TranslationMessages | null;
    maxAmountPerCustomer: number | null;
    includesAwards: number;
    excludesAward: boolean;
    requiresProductIds: number[];
    dependentProductIds: number[];
    includesProductIds: number[];
    includedByProductIds: number[];
    bookOnlyByMail: boolean;
    features: Record<string, unknown>[];
    highlight: boolean;
}

export function createTastingProduct(tastingProduct: MyCollections["tasting_products"]) {
    return <TastingProduct>{
        id: tastingProduct.id,
        previewImage: tastingProduct.preview_image || "",
        name: tastingProduct.name,
        availableAmount: tastingProduct.available_amount,
        isLimited: tastingProduct.is_limited || false,
        shortDescription: tastingProduct.short_description,
        description: tastingProduct.description,
        type: tastingProduct.type,
        stripeProduct: tastingProduct.stripe_product,
        defaultPrice: typeof tastingProduct.default_price !== "number"
            ? createTastingProductPrice(tastingProduct.default_price)
            : null,
        translations: createTranslationMessagesFromDb(tastingProduct.translations as any[]),
        maxAmountPerCustomer: tastingProduct.max_amount_per_customer,
        includesAwards: Number(tastingProduct.includes_awards || 0),
        excludesAward: tastingProduct.excludes_award || false,
        requiresProductIds: tastingProduct.requires_products?.map(
            requires_product => typeof requires_product !== "number"
                ? typeof requires_product.related_tasting_products_id !== "number"
                    ? requires_product.related_tasting_products_id?.id ?? null
                    : requires_product.related_tasting_products_id
                : []),
        dependentProductIds: tastingProduct.dependent_products?.map(
            dependent_product => typeof dependent_product !== "number"
                ? typeof dependent_product.tasting_products_id !== "number"
                    ? dependent_product.tasting_products_id?.id ?? null
                    : dependent_product.tasting_products_id
                : []),
        includesProductIds: tastingProduct.includes_products?.map(
            includesProduct => typeof includesProduct !== "number"
                ? typeof includesProduct.related_tasting_products_id !== "number"
                    ? includesProduct.related_tasting_products_id?.id ?? null
                    : includesProduct.related_tasting_products_id
                : []),
        includedByProductIds: tastingProduct.included_by_products?.map(
            includedByProduct => typeof includedByProduct !== "number"
                ? typeof includedByProduct.tasting_products_id !== "number"
                    ? includedByProduct.tasting_products_id?.id ?? null
                    : includedByProduct.tasting_products_id
                : []),
        bookOnlyByMail: tastingProduct.book_only_by_mail,
        features: tastingProduct.features != null ? tastingProduct.features : {},
        highlight: tastingProduct.highlight,
    }
}