<template>
  <div>
    <v-card-title class="pb-5">
      {{ getTranslation(boothProduct.translations, 'name') }}
    </v-card-title>

    <v-card-subtitle class="pb-0">
      <span v-if="boothProduct.defaultPrice.pricePrefix != null">
        {{ $t(boothProduct.defaultPrice.pricePrefix) }}
      </span>
      <span
        class="text--primary darken-2 font-weight-bold text-h5"
        v-html="boothProduct.defaultPrice.unitPrice"
      />
      <span class="text--primary darken-2 font-weight-bold pl-1">€</span>
      <span v-if="boothProduct.defaultPrice.priceSuffix != null">
        {{ $t(boothProduct.defaultPrice.priceSuffix) }}
      </span>
    </v-card-subtitle>

    <v-card-text
      v-if="boothProduct.availableAmount != null"
      class="text--secondary py-0 pb-1"
    >
      <strong>
        {{ boothProduct.availableAmount }}
      </strong> {{ $t('available') }}
    </v-card-text>

    <template v-for="(feature, index) in boothProduct.features">
      <feature-bullet
        :key="'feature' + index"
        :feature="feature"
      />
    </template>

    <v-card-text
      class="pb-2"
      v-html="getTranslation(boothProduct.translations, 'description', null, '&nbsp;')"
    />
  </div>
</template>

<script>
import FeatureBullet from "@/components/producer/submit/feature_bullet.vue";
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "BoothCard",
  components: {FeatureBullet},
  mixins: [translation_mixin],
  props: {
    boothProduct: { type: Object, required: true, default: null },
  },
}
</script>

<style scoped>
</style>