import {ApiBase, requiresLogin} from "@/api/apiBase";
import {CiderInfos, CProducerCiderResults, EditRevisionInfos} from "@/types/producerCider";
import {createKategorieFromDatabase, Kategorie} from "@/types/kategorie";
import {Country, createCountry} from "@/types/country";
import {createProducerTastingFromDatabase, ProducerPaidProducts, ProducerTasting, Tasting} from "@/types/tasting";
import {createProducerDataFromDb, ProducerData} from "@/types/producer";
import {MyCollections} from "@/api/types/my-collections";
import {createEingereichteCidersFromDb, EingereichteCiders, RevisionForCider} from "@/types/eingereichteCiders";
import {apiTyped} from "@/api/apiTyped";
import {Filter, PartialItem, TypeOf} from "@directus/sdk";
import axios from "axios";
import {createTastingProduct, TastingProduct} from "@/types/tastingProduct";
import {createTastingPurchase, TastingPurchase} from "@/types/tastingPurchase";


class ProducerApiMixin extends ApiBase {

    @requiresLogin
    async producerGetAllTastings(producerId: number) {
        const tastings = await this.sdk.items('tastings').readByQuery({
            filter: {
                "tables":{"flight_ids":{"flights_id":{"cider_ids":{"ciders_id":{"produzent":{"_eq": producerId}}}}}}
            }
        });
        return tastings.data;
    }

    @requiresLogin
    async producerGetCiderByIds(
        producerId: number,
        ciderIds: number[],
        onlyRootRevision = false
    ): Promise<CProducerCiderResults[] | undefined> {
        const filter = {
            id: {_in: ciderIds},
        };
        console.log("Producer get ciders", filter)
        const resp = await this.producerGetAllCiders(
            producerId, 0, 1, -1, filter, ["-date_updated"], onlyRootRevision);
        if (resp != null && resp.length > 0) {
            return resp;
        }
        return undefined;
    }

    @requiresLogin
    async producerGetCiderById(producerId: number, ciderId: number): Promise<CProducerCiderResults | undefined> {
        const filter = {
            id: {_eq: ciderId},
        };
        const resp = await this.producerGetAllCiders(producerId, 0, 1, 1, filter);
        if (resp != null && resp.length === 1) {
            return resp[0];
        }
        return undefined;
    }

    @requiresLogin
    async createProducerEntry(userId: string): Promise<any | undefined> {
        return await this.sdk.items("produzenten").createOne({
            user: userId,
        });
    }

    @requiresLogin
    async producerGetInfos(userId: string): Promise<any | undefined> {
        const resp = await this.sdk.items("produzenten").readByQuery({
            fields: [
                'id',
                'user',
                'name',
                'land.*',
                'sprache.*',
                'ciders.id',
                'assets.id',
                'assets.banner',
                'assets.logo',
                'assets.gallery.*',
                'cider_images_folder',
                'produzenten_data.*'
            ],
            filter: {
                user: { _eq: userId }
            },
            limit: 1,
        });
        console.log("resp.data", resp.data)
        let producerData;
        if (resp.data == null || resp.data[0] == null) {
            producerData = await this.createProducerEntry(userId);
        } else {
            producerData = resp.data[0];
        }
        if (producerData.produzenten_data == null) {
            // First login, create produzenten data
            const producerInfoData = await this.sdk.items("produzenten_data").createOne({
                user_id: userId,
            });
            if (producerInfoData == null) return undefined;  // error creating data
            await this.sdk.items("produzenten").updateOne(producerData?.id as number, {
                produzenten_data: producerInfoData.id,
            });
            producerData.produzenten_data = createProducerDataFromDb(producerData, null);
        }
        if (producerData.assets == null) {
            // First login, create produzenten assets
            const producerAssets = await this.sdk.items("produzenten_assets").createOne({});
            if (producerAssets == null) return undefined;  // error creating data
            await this.sdk.items("produzenten").updateOne(producerData?.id as number, {
                assets: producerAssets.id
            });
        }
        if (producerData.cider_images_folder == null) {

        }
        producerData.produzenten_data = createProducerDataFromDb(
            producerData.produzenten_data as MyCollections["produzenten_data"],
            producerData.assets as MyCollections["produzenten_assets"]);
        return producerData;
    }

    @requiresLogin
    async requestAccountDeletionProducer() {
        return await apiTyped.sdk.items("account_deletion_requests").createOne({status: 'draft'});
    }

    @requiresLogin
    async hasRequestAccountDeletionProducer(): Promise<boolean> {
        if (this.user?.id == null) {
            return false;
        }
        else {
            const userId = this.user?.id;
            const resp = await apiTyped.sdk.items("account_deletion_requests").readByQuery({
                filter: {
                    user_created: { _eq: userId },
                },
                limit: 1,
            });
            return resp != null;
        }
    }

    @requiresLogin
    async producerGetData(userId: string): Promise<ProducerData | undefined> {
        const resp = await this.sdk.items("produzenten").readByQuery({
            fields: [
                'produzenten_data.*',
                'produzenten_data.country.*',
                'assets.id',
                'assets.banner',
                'assets.logo',
                'assets.gallery.*',
            ],
            filter: {
                user: { _eq: userId }
            },
            limit: 1,
        });
        if (resp.data == null) return undefined;
        const producerData = resp.data[0]
        if (producerData.produzenten_data == null) return undefined;
        console.log("producerData", resp)
        return createProducerDataFromDb(
            producerData.produzenten_data as MyCollections["produzenten_data"],
            producerData.assets as MyCollections["produzenten_assets"]);
    }

    @requiresLogin
    async producerUpdateData(producerData: ProducerData): Promise<boolean> {
        console.log("Updating producerData", producerData)
        const resp = await this.sdk.items("produzenten_data").updateOne(producerData.id, {
            first_login: producerData.firstLogin,
            company_name: producerData.companyName,
            legal_form: producerData.legalForm,
            contact_person: producerData.contactPerson,
            street: producerData.street,
            street_number: producerData.streetNumber,
            city: producerData.city,
            postal_code: producerData.postalCode,
            province_state: producerData.province,
            country: producerData.country?.id,
            mobile_phone: producerData.mobilePhone,
            email: producerData.email,
            website: producerData.website,
            vat: producerData.vat,
            agree_data_publish: producerData.agreeDataPublish,
            company_description: producerData.companyDescription,
        });
        const names = producerData.contactPerson.trim().split(" ");
        const firstNames = names.slice(0, -1).join(" ") || undefined;
        const lastName = names.length > 1 ? names[names.length-1] || undefined : "";
        const resp2 = await this.sdk.users.me.update({
            first_name: firstNames,
            last_name: lastName,
        });
        await this.getUser();
        return !(resp == null || resp2 == null);
    }

    @requiresLogin
    async producerUpdateCompanyDescription(
        producerData: ProducerData,
        agreeDataPublish: boolean,
        description: string,
        imgBannerId: string | null,
        imgLogoId: string | null
    ): Promise<boolean> {
        const resp = await this.sdk.items("produzenten_data").updateOne(producerData.id, {
            agree_data_publish: agreeDataPublish,
            company_description: description,
        });
        if (resp == null || producerData.assets == null) return false;
        console.log("Update assets", producerData.assets.id, {
            banner: imgBannerId,
            logo: imgLogoId,
        })
        const resp2 = await this.sdk.items("produzenten_assets").updateOne(producerData.assets.id, {
            banner: imgBannerId,
            logo: imgLogoId,
        });
        console.log("Updated assets", resp2)
        return resp2 != null;
    }

    @requiresLogin
    async addFeedback(
        summary: string,
        feedbackText: string,
        origin: string,
        browserInfos = "",
        storeData: any = {}
    ) {
        const resp = await this.sdk.items("feedback").createOne({
            summary: summary,
            feedback_text: feedbackText.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            origin: origin,
            client_info: browserInfos,
            store_data: storeData,
        });
        return resp != null;

    }

    @requiresLogin
    async producerAddCider(
        ciderInfos: CiderInfos, producerId: number, revisionOf: number | null = null
    ): Promise<number | null> {
        // TODO move creation of db item to separate function and add it to create and update cider
        console.log("Updating cider info", ciderInfos)
        const ciderResp = await this.sdk.items("ciders").createOne({
            name: ciderInfos.name,
            display_id: "",
            produzent: producerId,
            profil: ciderInfos.profil,
            revision_of: revisionOf,
            // @ts-ignore  TODO
            profil_translations: ciderInfos.profilTranslations,
            alkohol: ciderInfos.alkohol,
            land: ciderInfos.land?.id,
            kategorie: ciderInfos.kategorieId === -1 ? null : ciderInfos.kategorieId,
            images: ciderInfos.images.map(i => {
                return { directus_files_id: i }
            }),

            vintage: ciderInfos.vintage,
            sales_designation: ciderInfos.salesDesignation,
            designation_of_origin: ciderInfos.designationOfOrigin,
            varieties: ciderInfos.varieties,
            residual_sugar: ciderInfos.residualSugar,
            residual_sugar_description: ciderInfos.residualSugarDescription,
            filtration_clarity: ciderInfos.filtrationClarity,
            pressure: ciderInfos.pressure,
            method: ciderInfos.method,
            bottle_content: ciderInfos.bottleContent,
            retail_price: ciderInfos.price,
        });
        return ciderResp?.id as number;
    }

    @requiresLogin
    async deleteCider(ciderId: number) {
        await this.sdk.items("ciders").deleteOne(ciderId);
    }

    @requiresLogin
    async producerUpdateCider(ciderId: number, ciderInfos: CiderInfos, producerId: number): Promise<boolean> {
        console.log("Got infos", ciderInfos, ciderId)
        const ciderResp = await this.sdk.items("ciders").updateOne(ciderId, {
            name: ciderInfos.name,
            display_id: "",
            produzent: producerId,
            profil: ciderInfos.profil,
            // @ts-ignore  TODO
            profil_translations: ciderInfos.profilTranslations || null,
            alkohol: ciderInfos.alkohol,
            land: ciderInfos.land?.id || null,
            kategorie: ciderInfos.kategorieId === -1 ? null : ciderInfos.kategorieId,
            images: ciderInfos.images.map(i => {
                return { directus_files_id: i }
            }),

            vintage: ciderInfos.vintage,
            sales_designation: ciderInfos.salesDesignation,
            designation_of_origin: ciderInfos.designationOfOrigin,
            varieties: ciderInfos.varieties,
            residual_sugar: ciderInfos.residualSugar,
            residual_sugar_description: ciderInfos.residualSugarDescription,
            filtration_clarity: ciderInfos.filtrationClarity,
            pressure: ciderInfos.pressure,
            method: ciderInfos.method,
            bottle_content: ciderInfos.bottleContent,
            retail_price: ciderInfos.price,
        });
        return ciderResp != null;

    }

    @requiresLogin
    async producerUploadCiderImages(files: File[], targetFolderId: string): Promise<string[] | undefined> {
        const forms = files.map(f => {
            const form = new FormData();
            form.append('file', f);
            return form;
        });
        // console.log("Forms", forms)
        const respPromises = forms.map(form => this.sdk.files.createOne(form));
        const responses = await Promise.all(respPromises);
        if (responses == null || responses.length === 0 || responses.some(r => r == null)) {
            // TODO Error
            return;
        }
        // console.log("responses", responses)
        const imageResp = await this.sdk.files.updateMany(responses.map(r => r?.id), {
            folder: targetFolderId,
        });
        // console.log("imageResp", imageResp)
        if (imageResp.data == null) return undefined;
        return imageResp.data.map(i => i.id);
    }

    @requiresLogin
    async producerGetAllCiders(
        producerId: number | null,
        offset = 0,
        page = 1,
        limit = 10,
        filter = {},
        sortBy = ["-date_updated"],
        onlyRootRevision = true,
    ): Promise<CProducerCiderResults[] | undefined> {
        // console.log("Requesting ciders", producerId, offset, page, limit, filter)
        const cidersResponse = await this.sdk.items('ciders').readByQuery({
            fields: [
                // when changing these field do not forget to change them below in producerGetCiderRevisionData!
                'id',
                'name',
                'date_created',
                'date_updated',
                'kategorie.id',
                'kategorie.name',
                'kategorie.translations.*',
                'land.*',
                'alkohol',
                'profil',
                'translations.*',
                'profil_translations',
                'images.directus_files_id',
                'produzent',

                // 'flight_ids.id',
                // 'flight_ids.points',
                // 'flight_ids.best_of_category',
                // 'flight_ids.badge.*',
                // 'flight_ids.chair_note.kommentar',
                // 'flight_ids.flights_id.tables.tables_id.tasting.id',
                // 'flight_ids.flights_id.tables.tables_id.tasting.finished',

                // 'flight_ids.flights_id.tables.tables_id.tasting.logo_messe',
                // 'flight_ids.flights_id.tables.tables_id.tasting.name',
                // 'flight_ids.flights_id.tables.tables_id.tasting.datum',
                // 'flight_ids.flights_id.tables.tables_id.tasting.beschreibung',
                'eingereichte_ciders.id',
                'eingereichte_ciders.status',
                'eingereichte_ciders.date_created',
                'eingereichte_ciders.date_updated',
                'eingereichte_ciders.cider_revision',
                'eingereichte_ciders.product_revision.id',
                'eingereichte_ciders.product_revision.flight_ids.id',
                'eingereichte_ciders.product_revision.flight_ids.ciders_id',
                'eingereichte_ciders.product_revision.flight_ids.points',
                'eingereichte_ciders.product_revision.flight_ids.best_of_category',
                'eingereichte_ciders.product_revision.flight_ids.badge.*',
                'eingereichte_ciders.product_revision.flight_ids.chair_note.kommentar',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.id',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.finished',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.results_published',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.expected_publish_date',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.published_results_for_producer.produzenten_id',
                'eingereichte_ciders.product_revision.flight_ids.flights_id.tables.tables_id.tasting.hidden_results_for_producer.produzenten_id',
                'eingereichte_ciders.kommentar',
                'eingereichte_ciders.tasting.id',
                'eingereichte_ciders.tasting.logo_messe',
                'eingereichte_ciders.tasting.name',
                'eingereichte_ciders.tasting.datum',
                'eingereichte_ciders.tasting.beschreibung',
                'eingereichte_ciders.tasting.abbreviation',
                'eingereichte_ciders.tasting.translations.*',
                'eingereichte_ciders.tasting.finished',
                'eingereichte_ciders.tasting.results_published',
                'eingereichte_ciders.tasting.expected_publish_date',

                'vintage',
                'sales_designation',
                'designation_of_origin',
                'varieties',
                'residual_sugar',
                'residual_sugar_description',
                'filtration_clarity',
                'pressure',
                'method',
                'bottle_content',
                'retail_price'
            ],
            filter: {
                ...(producerId != null && {produzent: {_eq: producerId}}),
                ...(onlyRootRevision && {revision_of: { _null: true }}),
                ...filter,
            },
            // @ts-ignore
            sort: sortBy,
            offset: offset,
            page: page,
            limit: limit
        });
        console.log("Got Ciders Resp", cidersResponse.data)
        if (cidersResponse?.data != null) {
            // await Promise.all(ciders.map(c => c.loadRevisions()));
            return cidersResponse.data.map(
                cider => new CProducerCiderResults(cider));
        }
        return undefined;
    }

    // @requiresLogin
    // async producerGetCiderFlightsRevisionData(ciderRevisionIds: number): Promise<MyCollections["ciders"] | null> {
    //     const ciderResponse = await this.sdk.items('ciders').readByQuery( {
    //         fields: [
    //             'flight_ids.id',
    //             'flight_ids.points',
    //             'flight_ids.best_of_category',
    //             'flight_ids.badge.*',
    //             'flight_ids.chair_note.kommentar',
    //             'flight_ids.flights_id.tables.tables_id.tasting.id',
    //             'flight_ids.flights_id.tables.tables_id.tasting.finished',
    //         ],
    //         filter: {
    //             id: {_in: ciderRevisionIds},
    //         },
    //         limit: -1,
    //     })
    // }

    @requiresLogin
    async producerGetCiderRevisionData(ciderId: number): Promise<MyCollections["ciders"] | null> {
        const ciderResponse = await this.sdk.items('ciders').readOne(ciderId, {
            fields: [
                // when changing these field do not forget to change them above in producerGetAllCiders!
                'id',
                'name',
                'kategorie.id',
                'kategorie.name',
                'kategorie.translations.*',
                'land.*',
                'alkohol',
                'profil',
                'translations.*',
                'profil_translations',
                'images.directus_files_id',

                'vintage',
                'sales_designation',
                'designation_of_origin',
                'varieties',
                'residual_sugar',
                'residual_sugar_description',
                'filtration_clarity',
                'pressure',
                'method',
                'bottle_content',
                'retail_price'
            ]
        });
        if (ciderResponse == null) return null;
        return ciderResponse;
    }

    @requiresLogin
    async getCurrentRevisionForCiders(ciders: CProducerCiderResults[]): Promise<RevisionForCider[] | undefined> {
        // TODO we assume a small number of ciders, so fire Promise.all for all promises, no pagination yet
        const revisionPromises = ciders.map(cider => this.sdk.revisions.readByQuery({
            fields: ['id', 'item'],
            filter: {
                collection: { _eq: 'ciders' },
                item: { _eq: cider.id },
            },
            sort: ['-activity.timestamp'],
            limit: 1,
        }));
        const revisionResponses = await Promise.all(revisionPromises);
        const data = revisionResponses.map((revisionPromise: any) => revisionPromise.data);
        if (data.some(d => d == null || d.length === 0)) return undefined;
        return data.map(d => {return {revisionId: Number(d[0].id), ciderId: Number(d[0].item)} });
    }

    @requiresLogin
    async getSubmittedCidersForTasting(tastingId: number, cidersIds: number[] | null = null,
    ): Promise<EingereichteCiders[] | undefined> {
        const filter = {
            tasting: { _eq: tastingId },
            user_created: { _eq: this.user?.id as string },
            ...(cidersIds != null && { cider: { _in: cidersIds } })
        };
        console.log("Filter", filter)
        const resp = await this.sdk.items("eingereichte_ciders").readByQuery({
            fields: [
                'id',
                'status',
                'date_created',
                'date_updated',
                'cider_revision',
                'kommentar',
                'cider',
                'product_revision',
                'tasting',
            ],
            filter: filter,
            limit: -1,  // TODO we assume a small number of ciders, so no pagination yet
        });
        console.log("Got ciders", resp)
        if (resp.data == null) return undefined;
        return resp.data.map(d => createEingereichteCidersFromDb(d));
    }

    @requiresLogin
    async deleteSubmittedCidersForTasting(eingereichteCiders: EingereichteCiders[]): Promise<void> {
        await this.sdk.items("eingereichte_ciders").deleteMany(eingereichteCiders.map(e => e.id));
        const toBeDeletedCiderRevisions = eingereichteCiders.filter(e => e.productRevision).map(e => e.productRevision);
        await this.sdk.items("ciders").deleteMany(toBeDeletedCiderRevisions);
    }

    @requiresLogin
    async updateSubmittedCidersStatus(
            eingereichteCiders: EingereichteCiders[], status: string, kommentar: string | null = null): Promise<boolean> {
        const newItem: PartialItem<TypeOf<MyCollections, "eingereichte_ciders">> = {status: status};
        if (kommentar != null) newItem.kommentar = kommentar;
        const resp = await this.sdk.items("eingereichte_ciders").updateMany(
            eingereichteCiders.map(e => e.id), newItem);
        return resp != null;

    }

    @requiresLogin
    async updateSubmittedCidersForTasting(
        eingereichteCiders: EingereichteCiders[],
        ciders: CProducerCiderResults[],
        tasting: Tasting,
    ): Promise<boolean> {
        const updatedEingereichteCidersData = eingereichteCiders.map(e => {
            const cider = ciders.find(c => c.id === e.cider);
            if (cider == null || cider.editRevisionInfos == null || cider.editRevisionInfos.revisionId == null || e.id == null) {
                console.error("Error updating Eingereichte ciders", eingereichteCiders, ciders, tasting);
                throw new Error("Error updating Eingereichte ciders");
            }
            // Update fields and create db entry
            return <MyCollections["eingereichte_ciders"]> {
                id: e.id,
                tasting: tasting.id,
                cider: cider.id,
                cider_revision: cider.editRevisionInfos.revisionId as number,
                kommentar: cider.editRevisionInfos.kommentar,
                status: cider.editRevisionInfos.status,
            }
        });
        const updatePromises = updatedEingereichteCidersData.map(
            u => this.sdk.items("eingereichte_ciders").updateOne(u.id as number, u));
        const responses = await Promise.all(updatePromises);
        return responses.every(r => r != null);
    }

    @requiresLogin
    async addSubmittedCidersForTasting(
        ciders: CProducerCiderResults[],
        tasting: Tasting,
        producerId: number,
        withoutProductData = false,
        tastingPurchaseId: number | null = null,
        status: "draft" | "unpaid" | "payment_success" | "request_refund" = "draft"
    ): Promise<{success: boolean, newItemIds: number[]}> {
        if (!withoutProductData) {
            if (ciders.some(cider => cider.editRevisionInfos == null)) {
                return {success: false, newItemIds: []};
            }

            for (const cider of ciders) {
                const newCiderId = await apiTyped.producerAddCider(cider.ciderInfos, producerId, cider.id);
                if (newCiderId == null) {
                    console.error("Error adding Cider:", cider, producerId, tasting, ciders);
                    return {success: false, newItemIds: []};
                }
                if (cider.editRevisionInfos == null) continue;  // sanity check
                cider.editRevisionInfos.revisionId = newCiderId;
            }
        }

        const createPromises = ciders.map(
            cider => this.sdk.items("eingereichte_ciders").createOne({
                tasting: tasting.id,
                cider: withoutProductData ? null : cider.id,
                ...(!withoutProductData && {cider_revision: (cider.editRevisionInfos as EditRevisionInfos).revisionId as number}),
                product_revision: withoutProductData ? null : (cider.editRevisionInfos as EditRevisionInfos).revisionId,
                ...(tastingPurchaseId != null && { tasting_purchase: tastingPurchaseId }),
                status: status,
                // kommentar: (cider.editRevisionInfos as EditRevisionInfos).kommentar,
            }));
        const responses = await Promise.all(createPromises);
        return { success: responses.every(r => r != null), newItemIds: responses.map(r => r?.id || -1) };
    }

    // @requiresLogin
    // async producerGetAllCidersFlights(producerId: number, offset = 0, page = 1, limit = 20) {
    //     const ciders = await this.sdk.items('flights_ciders').readByQuery({
    //         fields: [
    //             'id',
    //             'badge',
    //             'best_of_category',
    //             'points',
    //             'chair_note.kommentar',
    //             'ciders_id.name',
    //             'ciders_id.kategorie.id',
    //             'ciders_id.kategorie.name',
    //             'ciders_id.land.*',
    //             'ciders_id.alkohol',
    //             'ciders_id.profil',
    //             'ciders_id.translations.*',
    //             'ciders_id.profil_translations',
    //             'ciders_id.images.directus_files_id',
    //             'flights_id.tables.tables_id.tasting.id',
    //             'flights_id.tables.tables_id.tasting.logo_messe',
    //             'flights_id.tables.tables_id.tasting.name',
    //             'flights_id.tables.tables_id.tasting.datum',
    //             'flights_id.tables.tables_id.tasting.beschreibung',
    //         ],
    //         filter: {
    //             "ciders_id":{"produzent":{"_eq": producerId}},
    //         },
    //         offset: offset,
    //         page: page,
    //         limit: limit
    //     });
    //     return ciders;
    // }

    async getActiveKategorien(): Promise<Kategorie[] | undefined> {
        const kategorienResp = await this.sdk.items('kategorien').readByQuery({
            fields: ['*', 'translations.*.*'],
            filter: {
                producer_active: { _eq: true },
            },
            limit: -1,
        });
        if (kategorienResp?.data == null) return undefined;
        return kategorienResp.data.map((kategorie: any) => createKategorieFromDatabase(kategorie));
    }

    async getAllCountries(): Promise<Country[] | undefined> {
        const countriesResp = await this.sdk.items('countries').readByQuery({
            fields: ['id', 'name', 'code', '*'],
            limit: -1,
        });
        if (countriesResp?.data == null) return undefined;
        return countriesResp.data.map(c => createCountry(c)) as Country[];
    }

    // @requiresLogin
    // async producerGetCiderCountForTastings(tastings: Tasting[]): Promise<number[]> {
    //     const resp = await this.sdk.items('eingereichte_ciders').readByQuery({
    //         fields: ['id'],
    //         filter: {
    //
    //         },
    //         groupBy: ['tasting'],
    //         aggregate: {
    //             count: 'id',
    //         }
    //     });
    // }

    @requiresLogin
    async producerGetPaidProducts(tastingIds: number[], producerId: number): Promise<ProducerPaidProducts[]> {
        const resp = await this.sdk.items('produzenten').readOne(producerId, {
            fields: [
                'producer_paid_products.status',
                'producer_paid_products.tasting',
                'producer_paid_products.number_of_products',
            ],
            // TODO filter permissions are somehow not working
            // filter: {
            //     // @ts-ignore
            //     "producer_paid_products": { "tasting": { _in: tastingIds } }
            // },
        });
        if (resp == null) return [];
        return (resp as any).producer_paid_products?.map((t: any) => <ProducerPaidProducts>{
            status: t.status,
            tastingId: t.tasting,
            numberOfProducts: t.number_of_products,
        }) || [];
    }

    @requiresLogin
    async producerGetAvailableTastings(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        includeTastingInfos = false
    ): Promise<ProducerTasting[]> {
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {
                ...(tastingIds != null && { id: { _in: tastingIds}}),
                "_and": [
                    {
                        'einreichen_ab': { _lte: "$NOW" },
                    },
                    {
                        'einreichen_bis': { _gte: "$NOW" },
                    },
                ],
            },
            includeTastingInfos);
        if (tastings == null) return [];
        return tastings;
    }

    // @requiresLogin
    // async producerGetSubmittedTastingIds(
    //     offset = 0,
    //     page = 1,
    //     limit = 50
    // ): Promise<number[]>  {
    //     // Gets the ids of tastings having products submitted by this user
    //     if (this.user == null) return [];
    //     const resp = await this.sdk.items('eingereichte_ciders').readByQuery({
    //         fields: ['tasting'],
    //         filter: {
    //             user_created: { _eq: this.user.id },
    //         },
    //         limit: limit,
    //         offset: offset,
    //         page: page,
    //         groupBy: ['tasting'],
    //     });
    //     if (resp.data == null) return [];
    //     return resp.data as number[];
    // }

    @requiresLogin
    async producerGetUpcomingTastings(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        excludeTastingIds: number[] | null = null,
        includeTastingInfos = false,
        searchText: string | null = null,
        sortBy: string[] = ['-datum'],
    ): Promise<ProducerTasting[]> {
        if (this.user == null) return [];
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {
                ...(tastingIds != null && { id: { _in: tastingIds}}),
                ...(excludeTastingIds != null && excludeTastingIds.length > 0 && { id: { _nin: excludeTastingIds}}),
                "_or": [
                    {
                        'einreichen_ab': { _gte: "$NOW" },
                    },
                    // {
                    //     "eingereichte_ciders":{"user_created":{"_eq": this.user.id}},
                    // },
                ],
            },
            includeTastingInfos,
            searchText,
            sortBy);
        if (tastings == null) return [];
        return tastings;
    }

    @requiresLogin
    async producerGetPastTastings(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        excludeTastingIds: number[] | null = null,
        includeTastingInfos = false,
        searchText: string | null = null,
        sortBy: string[] = ['-datum'],
    ): Promise<ProducerTasting[]> {
        if (this.user == null) return [];
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {
                ...(tastingIds != null && { id: { _in: tastingIds}}),
                ...(excludeTastingIds != null && excludeTastingIds.length > 0 && { id: { _nin: excludeTastingIds}}),
                "_or": [
                    {
                        'einreichen_bis': { _lte: "$NOW" },
                    },
                    // {
                    //     "eingereichte_ciders":{"user_created":{"_eq": this.user.id}},
                    // },
                ],
            },
            includeTastingInfos,
            searchText,
            sortBy);
        if (tastings == null) return [];
        return tastings;
    }

    @requiresLogin
    async producerGetOpenTastings(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        excludeTastingIds: number[] | null = null,
        includeTastingInfos = false,
        searchText: string | null = null,
        sortBy: string[] = ['-datum'],
    ): Promise<ProducerTasting[]> {
        if (this.user == null) return [];
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {
                ...(tastingIds != null && { id: { _in: tastingIds}}),
                ...(excludeTastingIds != null && excludeTastingIds.length > 0 && { id: { _nin: excludeTastingIds}}),
                "_or": [
                    {
                        "einreichen_bis": { "_gte": "$NOW" },
                        "einreichen_ab": { "_lte": "$NOW" }                    },
                    // {
                    //     "eingereichte_ciders":{"user_created":{"_eq": this.user.id}},
                    // },
                ],
            },
            includeTastingInfos,
            searchText,
            sortBy);
        if (tastings == null) return [];
        return tastings;
    }

    @requiresLogin
    async producerGetMyTastings(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        includeTastingInfos = false,
        searchText: string | null = null,
        sortBy: string[] = ['-datum'],
    ): Promise<ProducerTasting[]> {
        if (this.user == null) return [];
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {
                ...(tastingIds != null && { id: { _in: tastingIds}}),
                _or: [
                    {
                        "eingereichte_ciders": {
                            "user_created": { "_eq": this.user.id },
                            // "status": { "_nin": ['archived', 'refunded'] }
                        }
                    },
                    {
                        "tasting_purchases": {
                            "user_created": { "_eq": this.user.id },
                            "status": { "_neq": 'draft' }
                        }
                    },
                ]
            },
            includeTastingInfos,
            searchText,
            sortBy);
        if (tastings == null) return [];
        return tastings;
    }

    @requiresLogin
    async producerGetTastingsByIds(
        offset = 0,
        page = 1,
        limit = 50,
        tastingIds: number[] | null = null,
        includeTastingInfos = false
    ): Promise<ProducerTasting[]> {
        const tastings = await this.producerGetTastings(
            offset, page, limit,
            {...(tastingIds != null && { id: { _in: tastingIds}}),},
            includeTastingInfos);
        if (tastings == null) return [];
        return tastings;
    }

    @requiresLogin
    private async producerGetTastings(
        offset = 0,
        page = 1,
        limit = 50,
        filter: Filter<"tastings">,
        includeTastingInfos = false,
        searchText: string | null = null,
        sortBy: string[] = ['-datum'],
    ): Promise<ProducerTasting[]> {
        const fields = [
            'id',
            'name',
            'status',
            'datum',
            'date_created',
            'started',
            'finished',
            'results_published',
            'beschreibung',
            'einreichen_ab',
            'einreichen_bis',
            'logo_messe',
            'eingereichte_ciders.id',
            'tasting_purchases.id',
            'tasting_purchases.status',
            'tasting_purchases.user_created',
            'translations.*',
            'tasting_settings.*',
            'event_country.*',
            'event_city',
            'tasting_prices.*',
            'tasting_settings.cider_info_languages.languages_code.*',
            'abbreviation',
        ];
        if (includeTastingInfos) {
            fields.push(...[
                'tasting_infos.*',
                'tasting_infos.translations.*',
                'tasting_infos.partners.*.*',
            ]);
        }
        const resp = await this.sdk.items('tastings').readByQuery({
            fields: fields,
            filter: {
                ...filter,
                status: { _eq: "published" }
            },
            limit: limit,
            offset: offset,
            page: page,
            ...(searchText != null && {search: searchText}),
            // @ts-ignore
            sort: sortBy
        });
        console.log("Got tastings producer", resp)
        if (resp.data == null) return [];
        return resp.data.map(t => createProducerTastingFromDatabase(t));
    }

    @requiresLogin
    async uploadPrintData(id: string, data: any): Promise<number | undefined> {
        const resp = await this.sdk.items("print_data").createOne({
            id: id,
            data: data,
        });
        console.log("GOT DATA", resp)
        if (resp == null) return undefined;
        // @ts-ignore
        return resp;
    }

    async getPrintData(uuid: string): Promise<any | undefined> {
        const path = "print/getPrintData"
        try {
            const resp = await this.sdk.transport.get(
                path, { params: { uuid: uuid } });
            console.log("GOT PRINT RES", resp)
            return resp.raw.hasOwnProperty("data") ? resp.raw : undefined;
        } catch (e) {
            console.error(e);
            return undefined
        }
    }

    async getOverviewA4Download(data: any): Promise<any | undefined> {
        const path = this.baseUrl + "print/printOverviewA4"
        return await axios.post(
            path,
            {data: data},
            {responseType: 'arraybuffer', headers: {Authorization: `Bearer ${this.sdk.auth.token}`}}
        );
    }

    async getCertificateA4Download(data: any): Promise<any | undefined> {
        const path = this.baseUrl + "print/printCertificateA4"
        return await axios.post(
            path,
            {data: data},
            {responseType: 'arraybuffer', headers: {Authorization: `Bearer ${this.sdk.auth.token}`}}
        );
    }

    @requiresLogin
    async producerGetSalesDesignations(categoryId: number): Promise<any[]> {
        const resp = await this.sdk.items('sales_designations').readByQuery( {
            fields: [
                'sales_designation'
            ],
            filter: {
                // @ts-ignore
                "category": { _eq: categoryId }
            },
            limit: -1
        });
        if (resp == null) return [];
        // @ts-ignore
        return resp.data?.map(d => d.sales_designation);
    }

    @requiresLogin
    async producerGetDesignationOfOrigins(): Promise<any[]> {
        const resp = await this.sdk.items('product_regions').readByQuery( {
            fields: [
                'product_region'
            ],
            limit: -1
        });
        if (resp == null) return [];
        // @ts-ignore
        return resp.data?.map(s => s.product_region);
    }

    @requiresLogin
    async producerGetTastingPurchaseById(tastingPurchaseId: number | null): Promise<TastingPurchase | null> {
        if (tastingPurchaseId == null) return null;
        console.log("Getting purchase for", tastingPurchaseId)
        const resp = await this.sdk.items('tasting_purchases').readOne(tastingPurchaseId, {
            fields: [
                '*',
                'purchased_products.id',
                'purchased_products.amount',
                'purchased_products.tasting_product_price.*',
                'purchased_products.tasting_product.*',
                'purchased_products.tasting_product.translations.*',
            ],
            filter: {
                "status": { _neq: "draft" }
            }
        });
        console.log("Got purchases", resp)
        if (resp == null) return null;
        return createTastingPurchase(resp);
    }

    @requiresLogin
    async producerGetTastingPurchases(tastingId: number): Promise<TastingPurchase[] | null> {
        console.log("Getting purchase for", this.user?.id)
        const resp = await this.sdk.items('tasting_purchases').readByQuery( {
            fields: [
                '*',
                'purchased_products.id',
                'purchased_products.amount',
                'purchased_products.position',
                'purchased_products.tasting_product_price.*',
                'purchased_products.tasting_product.*',
                'purchased_products.tasting_product.translations.*',
                'invoices.*'
            ],
            filter: {
                "status": { _neq: "draft" },
                "tasting": { _eq: tastingId },
                "user_created": { _eq: this.user?.id as string },
            },
            sort: ["-date_created"],
            limit: -1
        });
        console.log("Got purchases", resp.data)
        if (resp.data == null || resp.data[0] == null) return null;
        return resp.data.map(d => createTastingPurchase(d));
    }

    @requiresLogin
    async producerRefundTastingPurchases(tastingPurchaseId: number): Promise<boolean | null> {
        const resp = await this.sdk.items('tasting_purchases').updateOne(tastingPurchaseId, {
            status: "request_refund"
        });
        return resp != null;
    }

    @requiresLogin
    async producerGetTastingProducts(tastingId: number, onlyPublished = false): Promise<TastingProduct[] | null> {
        const resp = await this.sdk.items('tasting_products').readByQuery( {
            fields: [
                '*',
                'translations.*',
                'default_price.*',
                'includes_products.*',
                'included_by_products.*',
                'requires_products.*',
                'dependent_products.*'
            ],
            filter: {
                "tasting": { _eq: tastingId },
                ...(onlyPublished && { "status": { _eq : "published" } })
            },
            limit: -1
        });
        console.log("GOT tasting products", resp)
        if (resp.data == null) return null;
        return resp.data.filter((d: any) => d.default_price != null).map((d: any) => createTastingProduct(d));
    }

    @requiresLogin
    async createCheckoutSession(
        tastingId: number,
        tastingProducts: {id: number, amount: number}[],
        submittedProductIds: number[],
        useStripe = false,
        totalAmount: number | null = null,
        subtotalAmount: number | null = null,
        taxAmount: number | null = null
    ): Promise<string | null> {
        const path = "stripe/create-checkout-session"
        console.log("Create session", tastingId, tastingProducts)
        const resp = await this.sdk.transport.post(path, {
            "tastingProducts": tastingProducts,
            "tastingId": tastingId,
            "submittedProductIds": submittedProductIds,
            "useStripe": useStripe,
            "totalAmount": totalAmount,
            "subtotalAmount": subtotalAmount,
            "taxAmount": taxAmount
        });
        console.log("CreatedCheckoutSession", resp)
        if (useStripe){
            return resp.raw.hasOwnProperty("session_url") ? resp.raw["session_url"] : null;
        } else {
            return resp.raw.hasOwnProperty("status") ? resp.raw : null;
        }
    }

    // async testFilter() {
    //     const test = await this.sdk.items("eingereichte_ciders").readByQuery({
    //         fields: ['*'],
    //         filter: {
    //             "cider":{"produzent":{"id":{_eq: 404}}},
    //         },
    //         limit: -1,
    //     });
    //     console.log("GOT TEST", test)
    // }

}

export {ProducerApiMixin}