<template>
  <v-row
    justify="center"
    class="no_scrollbar"
  >
    <v-dialog
      v-model="dialog"
      persistent
      class="no_scrollbar"
    >
      <v-card
        flat
        color="background"
        class="overflow-y-auto rounded-xl"
      >
        <v-card-text>
          <v-row
            class="d-flex flex-column rounded-xl overflow-y-auto py-3"
            dense
            align="center"
            justify="center"
            :class="{ 'primary lighten-4': dragover, 'elevation-4': dragover }"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <v-icon
              :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
              size="60"
              @click="$refs.inputUploadFile.click()"
            >
              mdi-cloud-upload
            </v-icon>
            <label for="inputUploadFile">
              <input
                id="inputUploadFile"
                ref="inputUploadFile"
                type="file"
                multiple
                style="display: none;"
                @change="requestUploadFile"
              >
              {{ $t('uploadImages.dropFilesHere') }}

            </label>
          </v-row>

          <div
            :key="chipList"
            class="py-2"
          >
            <draggable
              v-model="documents"
            >
              <v-chip
                v-for="(document, index) in documents"
                :key="index"
                class="ma-1"
                close
                @click:close="removeFile(document)"
              >
                <div>
                  {{ document.file.name }} (x{{ document.repeat }})
                </div>
                <v-edit-dialog
                  @save="setRepeatValue(document, index)"
                  @close="setRepeatValue(document, index)"
                >
                  <v-icon
                    small
                    class="ml-1"
                    v-text="'mdi-pencil'"
                  />
                  <template #input>
                    <v-text-field
                      v-model="editRepeat"
                      type="number"
                      :rules="[v => (Number.isInteger(Number(v))) || 'Enter a integer number']"
                      label="Repeat File"
                      single-line
                    />
                  </template>
                </v-edit-dialog>
              </v-chip>
            </draggable>
          </div>

          <div class="d-flex flex-row align-baseline">
            <v-text-field
              v-model="filenameTemplate"
              label="File Name Template"
              outlined
              rounded
              dense
            >
              <template #append>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <div class="justify-start text-left">
                    <strong>The following variables can be used:</strong><br>
                    - name (product name)<br>
                    - fileName (uploaded file name)<br>
                    - producerName (producer name)<br>
                    - companyName  (company name)<br>
                    - points (total points for tasting)<br>
                    - country (international country name)<br>
                  </div>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>

          <div class="d-flex flex-row align-baseline">
            <v-text-field
              v-model="iconInput"
              label="Icon"
              outlined
              rounded
              dense
              :append-icon="iconInput"
              style="max-width: 400px;"
            />
            <v-text-field
              v-model="folderId"
              label="Folder ID"
              persistent-hint
              :hint="folderName"
              :error="folderNameError"
              outlined
              rounded
              dense
              class="pl-3"
              append-icon="mdi-folder"
              style="max-width: 400px;"
            />
            <v-spacer />
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  style="text-transform: unset;"
                  text
                  rounded
                  class="pr-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-sort-variant
                  </v-icon>
                  {{ sortByText }}
                  <v-btn
                    class="ml-1 animate_change"
                    icon
                    :class="{'rotate-180': sortDesc}"
                    @click="sortDesc = !sortDesc"
                    @click.native.stop=""
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>
                  Sort by
                </v-subheader>
                <v-list-item
                  v-for="(item, index) in sortByList"
                  :key="index"
                  link
                  @click="sortBy = item.value; sortByText = item.text;"
                >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-card
            v-if="cidersSorted.length > 0"
            class="mx-auto mt-3 overflow-y-auto rounded-xl"
            min-width="100"
            tile
            :outlined="$vuetify.theme.dark"
            style="overflow: auto"
          >
            <v-data-table
              :key="dataTableKey"
              :items="cidersSorted"
              :headers="allHeaders"
              :hide-default-footer="false"
              disable-sort
              item-key="uniqueId"
              :disable-pagination="false"
              :items-per-page="50"
              :footer-props="{'items-per-page-options': [10, 25, 50, 100, 250, 500]}"
              fixed-header
              :item-class="rowClass"
              height="calc(100vh - 128px - 64px - 24px - 108px)"
            >
              <template #group.header="{ group, groupBy, headers, toggle, remove, isOpen, items }">
                <td
                  :colspan="headers.length"
                  style="min-height: 48px;"
                  :style="{'background-color': $vuetify.theme.currentTheme.light_grey}"
                >
                  <div class="d-flex flex-row align-center justify-center">
                    <v-btn
                      :ref="group"
                      x-small
                      icon
                      @click="toggle"
                    >
                      <transition name="fade">
                        <v-icon v-if="isOpen">
                          mdi-chevron-up
                        </v-icon>
                        <v-icon v-else>
                          mdi-chevron-down
                        </v-icon>
                      </transition>
                    </v-btn>
                    <!-- Custom Header Groups -->
                    <span
                      v-if="groupBy[0] === 'stateText'"
                      class="d-flex align-center mx-5 font-weight-bold"
                    >
                      <v-btn
                        icon
                        small
                        class="d-flex animate_change d-flex"
                        @click.native.stop=""
                      >
                        <v-icon
                          class="d-flex align-center justify-center ma-auto"
                          style="display: inline-flex !important"
                          :color="getCiderStateIconColor(items[0])"
                        >
                          {{ items[0].stateIcon }}
                        </v-icon>
                      </v-btn>
                      {{ items[0].stateText }}
                    </span>
                    <span
                      v-else-if="groupBy[0] === 'land.name'"
                      class="d-flex align-center mx-5 font-weight-bold"
                    >
                      <country-flag
                        class="elevation-2"
                        :country="items[0].land.code"
                        size="normal"
                      />
                      {{ items[0].land.name }}
                    </span>
                    <span
                      v-else-if="groupBy[0] === 'kategorie.name'"
                      class="d-flex align-center mx-5 font-weight-bold"
                    >
                      {{ items[0].kategorie.displayId || items[0].kategorie.id }}: {{ items[0].kategorie.name }}
                    </span>
                    <span
                      v-else-if="groupBy[0] === 'table'"
                      class="mx-5 font-weight-bold"
                    >
                      {{ items[0].table.nummer }}
                    </span>
                    <span
                      v-else-if="groupBy[0] === 'badgeData.punkteMin'"
                      class="d-flex mx-5 font-weight-bold"
                    >
                      <img
                        alt="Badge"
                        class="align-center animate_change mt-0"
                        style="height: 35px; width: 35px;"
                        :src="getBadgeUrl(300, items[0].totalPoints)"
                      >
                      <span class="d-flex align-center ml-2">
                        {{ items[0].badgeData.punkteMin }} - {{ items[0].badgeData.punkteMax }} Punkte
                      </span>
                    </span>
                    <!-- Default Header Group -->
                    <span
                      v-else
                      class="mx-5 font-weight-bold"
                    >{{ group }}</span>
                    <v-btn
                      :ref="group"
                      x-small
                      fab
                      icon
                      @click="remove"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </div>
                </td>
              </template>
              <template #item.stateText="{ item }">
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="animate_change d-flex"
                      @click.native.stop=""
                      v-on="on"
                    >
                      <v-icon
                        class="d-flex align-center justify-center ma-auto"
                        style="display: inline-flex !important"
                        :color="getCiderStateIconColor(item)"
                      >
                        {{ item.stateIcon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.stateText }}</span>
                </v-tooltip>
              </template>
              <template #item.uniqueId="{ item }">
                <div
                  class="d-flex justify-center"
                  style="gap: 0 !important;"
                >
                  <v-menu
                    top
                    offset-y
                    transition="scale-transition"
                    origin="bottom left"
                    class="d-flex justify-start"
                    max-width="600px"
                    open-on-hover
                  >
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >{{ item.displayId }}</span>
                    </template>
                    <CiderMenu
                      :cider="item"
                      :table="item.table"
                      :show="false"
                    />
                  </v-menu>
                </div>
              </template>
              <template #item.kategorie.name="{ item }">
                <div
                  class="d-flex justify-center"
                  style="gap: 0 !important;"
                >
                  {{ item.kategorie.displayId }}: {{ item.kategorie.name }}
                </div>
              </template>
              <template #item.land.name="{ item }">
                <div
                  class="d-flex justify-start align-center pa-0"
                  style="gap: 0 !important;"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <country-flag
                          class="elevation-2"
                          :country="item.land.code"
                          size="normal"
                        />
                      </div>
                    </template>
                    <span>{{ item.land.name || item.land }}</span>
                  </v-tooltip>
                  <!--            {{ item.produzent }}-->
                </div>
              </template>
              <template #item.table="{ item }">
                <div class="d-flex justify-center">
                  {{ item.table.nummer }}
                </div>
              </template>
              <template #item.notes.kommentar="{ item }">
                <div>{{ item.notes.kommentar }}</div>
                <!--              <tr>-->
                <!--                <td class="truncate" >{{ item.kommentar}}</td>-->
                <!--              </tr>-->
              </template>
              <template #item.totalPoints="{ item }">
                <!--              <v-chip class="elevation-1" @click="cider_click(item)" :color="getColor(item.punkte)" dark text-color="black" >-->
                <!--                {{ item.punkte }}-->
                <!--              </v-chip>-->
                <ChipPoints
                  :points="item.totalPoints"
                  :badge-data="item.badgeData"
                  :round-to="0.1"
                />
                <!--          <v-chip-->
                <!--            label-->
                <!--            style="min-width: 70px; max-width: 70px; margin:0 auto"-->
                <!--            class="pl-1 pr-1 d-flex justify-center animate_change"-->
                <!--            :outlined="$store.getters['badgesStore/getColor'](item.totalPoints) === null"-->
                <!--            :color="$store.getters['badgesStore/getColor'](item.totalPoints) !== null -->
                <!--              ? $store.getters['badgesStore/getColor'](item.totalPoints) : '#aaa'"-->
                <!--            dark-->
                <!--            :text-color="'#' + $store.getters['badgesStore/getTextColor'](item.totalPoints, $vuetify.theme.dark)"-->
                <!--            @click="clickTable(item)"-->
                <!--          >-->
                <!--            <v-icon-->
                <!--              v-if="item.totalPoints > 0"-->
                <!--              small-->
                <!--              class=""-->
                <!--            >-->
                <!--              mdi-star-->
                <!--            </v-icon>-->
                <!--            {{ item.totalPoints }}-->
                <!--          </v-chip>-->
              </template>
              <template #item.badgeData.punkteMin="{ item }">
                <div class="d-flex justify-center animate_change">
                  <badge
                    :badge-data="item.badgeData"
                    :badge-width="36"
                  />
                  <!--            <img-->
                  <!--              alt="None"-->
                  <!--              class="animate_change"-->
                  <!--              style="height: 35px; width: 35px; margin: auto"-->
                  <!--              :src="getBadgeUrl(300, item.totalPoints)"-->
                  <!--            >-->
                </div>
              </template>
              <template #item.documentName="{ item }">
                <div class="d-flex justify-center animate_change align-bottom">
                  <span
                    v-if="item.document != null"
                    class="text--secondary"
                  >
                    {{ item.document.file.name }}
                  </span>
                  <span
                    v-else
                    class="text--secondary"
                  >
                    No document
                  </span>
                </div>
              </template>
              <template #item.newDocumentName="{ item }">
                <div class="d-flex flex-column justify-center animate_change align-bottom">
                  <div v-if="item.document != null">
                    <span class="mr-2">
                      {{ makeFilename(item) }}
                    </span>
                    <a
                      id="link"
                      class="justify-center align-baseline"
                      style="text-decoration: none !important;"
                      :download="item.document.file.name"
                      :href="item.document.url"
                    >
                      <v-icon
                        class="mx-1"
                        v-text="'mdi-file-download'"
                      />
                    </a>
                  </div>
                </div>
              </template>
              <template #item.exclude="{ item }">
                <v-switch
                  v-model="item.exclude"
                  inset
                  @click="addDocumentsToCiders(documents)"
                />
              </template>
              <template #item.documentPreview="{ item }">
                <div
                  v-if="item.document != null"
                  class="d-flex justify-center animate_change align-bottom"
                >
                  <v-menu
                    open-on-hover
                    left
                    offset-x
                    offset-y
                  >
                    <template #activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <vue-pdf-embed
                          v-if="item.document.file.name.toLowerCase().endsWith('.pdf')"
                          style="max-height: 32px;"
                          height="32"
                          :source="item.document.url"
                        />
                      </div>
                    </template>
                    <div style="width: 600px;">
                      <vue-pdf-embed
                        v-if="item.document.file.name.toLowerCase().endsWith('.pdf')"
                        width="600"
                        :source="item.document.url"
                      />
                    </div>
                  </v-menu>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-card-text class="text-left pt-0 pb-0 text--secondary text-caption align-center">
            <v-icon small>
              mdi-information-outline
            </v-icon>
            <span>
              Maximum size limit is 8 mb per file
            </span>
          </v-card-text>
          <v-spacer />
        </v-card-actions>
        <v-expand-transition>
          <div
            v-show="loading"
            class="mx-6"
          >
            <v-progress-linear
              rounded
              class="animate-progress"
              height="8"
              :value="progress"
            />
            <span>
              Uploading document for cider {{ currentUploadingCider.name }}
            </span>
          </div>
        </v-expand-transition>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            rounded
            color="error"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            rounded
            :loading="loading"
            color="primary"
            @click="upload"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import Badge from "@/components/badge.vue";
import CountryFlag from "vue-country-flag";
import CiderMenu from "@/components/admin/menu/cider_menu.vue";
import ChipPoints from "@/components/chip_points.vue";
import i18n from "@/i18n";
import {apiTyped} from "@/api";
import draggable from 'vuedraggable'

export default {
  name: "UploadProducerDocumentsModal",
  components: {draggable, ChipPoints, CiderMenu, Badge, CountryFlag, VuePdfEmbed},
  mixins: [cider_mixin, user_mixin],
  data: () => ({
    dataTableKey: -1,
    chipList: 1,
    dialog: false,
    resolve: null,
    reject: null,
    dragover: false,
    loading: false,
    editRepeat: 1,
    currentUploadingCider: "",
    progress: 0,
    sortBy: "totalPoints",
    sortByText: "Points",
    sortDesc: false,
    filenameTemplate: "",
    iconInput: "",
    folderId: "7690fa47-b4fb-468a-9f90-bcf451b31eb7",
    ciders: [],
    cidersSorted: [],
    documents: [],
    folderName: "ProductFiles",
    folderNameError: false,
    sortByList: [
      {
        text: "Number",
        value: 'displayId'
      },
      {
        text: "Points",
        value: 'totalPoints'
      },
      {
        text: "Product Name",
        value: 'name'
      },
      {
        text: "Category",
        value: 'category'
      },
      {
        text: "Company Name",
        value: 'companyName'
      }
    ],
    variables: []
  }),
  computed: {
    allHeaders() {
      return [
        {text: i18n.t('status'), value: 'stateText', align: 'center', sortable: true, groupable: true},
        {text: "#Nr.", value: 'displayId', align: 'center', sortable: true, groupable: false},
        {text: 'Table', value: 'table', align: 'center', sortable: true, groupable: true},
        {text: i18n.tc('flight', 2) + ' ', value: 'flightName', align: 'center', sortable: true, groupable: true},
        {text: 'Name',  value: 'name', align: 'center', sortable: true, groupable: false},
        {text: i18n.tc('category', 1),  value: 'kategorie.name', align: 'center', sortable: true, groupable: true, width: 164},
        {text: 'Land', value: 'land.name', align: 'center', sortable: true, groupable: true},
        {text: 'Produzent', value: 'companyName', align: 'center', sortable: true, groupable: true},
        {text: i18n.tc('point', 2), value: 'totalPoints', align: 'center', sortable: true, groupable: false},
        {text: 'Award ', value: 'badgeData.punkteMin', align: 'center', sortable: true, groupable: true},
        {text: 'Original Name', value: 'documentName', align: 'center', sortable: false, groupable: false},
        {text: 'New Document Name', value: 'newDocumentName', align: 'center', sortable: false, groupable: false},
        {text: 'Preview', value: 'documentPreview', align: 'center', sortable: false, groupable: false},
        {text: 'Exclude', value: 'exclude', align: 'center', sortable: false, groupable: false},
      ]
    },
  },
  watch: {
    folderId: async function (newFolderId) {
      try {
        const folder = await apiTyped.sdk.folders.readOne(newFolderId);
        this.folderName = folder.name;
        this.folderNameError = false;
      } catch (e) {
        this.folderName = e.message;
        this.folderNameError = true;
      }
    },
    documents: function (newDocuments) {
      this.addDocumentsToCiders(newDocuments);
    },
    sortBy: function () {
      this.sortCiders();
      this.addDocumentsToCiders(this.documents);
    },
    sortDesc: function () {
      this.sortCiders();
      this.addDocumentsToCiders(this.documents);
    },
  },
  methods: {
    sortCiders() {
      if (this.sortBy === "displayId")
        this.cidersSorted.sort((c1, c2) => Number(c1.displayId) - Number(c2.displayId));
      if (this.sortBy === "name")
        this.cidersSorted.sort((c1, c2) => c2.name.localeCompare(c1.name));
      if (this.sortBy === "totalPoints")
        this.cidersSorted.sort((c1, c2) => c2.totalPoints - c1.totalPoints);
      if (this.sortBy === "category")
        this.cidersSorted.sort((c1, c2) => c2.kategorie.name.localeCompare(c1.kategorie.name));
      if (this.sortBy === "companyName")
        this.cidersSorted.sort((c1, c2) => c2.companyName.localeCompare(c1.companyName));
      if (this.sortDesc)
        this.cidersSorted = this.cidersSorted.reverse();
    },
    rowClass(item) {
      console.log("Item is excluded", item)
      if (item.exclude) {
        return ["excluded-row"];
      }
    },
    setRepeatValue(document, index) {
      console.log("Set", index, Number(this.editRepeat))
      this.documents[index].repeat = Number(this.editRepeat);
      this.editRepeat = 1;
      this.chipList += 1;
      this.addDocumentsToCiders(this.documents);
    },
    addDocumentsToCiders(documents) {
      const documentsFlat = documents.flatMap(d => Array(d.repeat).fill(d))
      let idx = 0;
      for (const c of this.cidersSorted) {
        if (c.exclude === false && idx < documentsFlat.length) {
          c.document = documentsFlat[idx];
          idx += 1;
        } else {
          delete c.document;
        }
      }
      this.dataTableKey = -1 * this.dataTableKey;  // rerender table
    },
    open(ciders) {
      this.ciders = ciders;
      this.cidersSorted = [...this.ciders];  // make copy of ciders
      this.cidersSorted.forEach(c => {
        c.exclude = false;
        if (c.hasOwnProperty("document")) delete c.document;
      });
      this.sortCiders();
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    async upload() {
      if (this.folderNameError) {
        return;
      }
      this.loading = true;
      this.progress = 0;
      const cidersWithDocuments = this.cidersSorted.filter(c => c.document != null);
      const progressStep = 100 / cidersWithDocuments.length;
      const uploadedFileIds = new Map();
      for (const cider of cidersWithDocuments) {
        this.currentUploadingCider = cider;
        const uploadedFileId = uploadedFileIds.has(cider.document.url) ? uploadedFileIds.get(cider.document.url) : null;
        console.log("uploadedFileId", uploadedFileId)
        const apiUploadedFileId = await apiTyped.addFileToFlightCider(
          cider.flightsCidersId,
          cider.document.file,
          this.makeFilename(cider),
          this.iconInput,
          this.folderId,
          uploadedFileId
        );
        if (apiUploadedFileId == null) {
          throw new Error(`Error for cider ${cider}`);
        }
        uploadedFileIds.set(cider.document.url, apiUploadedFileId);
        this.progress += progressStep;
      }
      this.resolve(true);
      this.loading = false;
      this.dialog = false;
      this.progress = 0;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    makeFilename(cider) {
      const fileName = cider.document != null && cider.document.file.name != null ? cider.document.file.name : "";
      let name = this.filenameTemplate
        .replace("{{name}}", cider.name)
        .replace("{{fileName}}", fileName)
        .replace("{{producerName}}", cider.companyName)
        .replace("{{companyName}}", cider.companyName)
        .replace("{{points}}", cider.totalPoints)
        .replace("{{country}}", cider.land.name || 'none')
      if (name === "") name = fileName;
      const extension = cider.document != null && cider.document.file.name != null
        ? "." + cider.document.file.name.split('.').pop()
        : "";
      if (name.endsWith(extension)) {
        name = name.slice(0, extension.length);
      }
      // if (cider.document != null && cider.document.file.name != null)
      //   name = name.slice(0, -(cider.document.file.name.split('.').pop().length+1));
      return `${name.replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-')}${extension}`;
    },
    createLocalDownloadUrl(file) {
      return URL.createObjectURL(file);
    },
    removeFile(document) {
      const index = this.documents.findIndex(document_ => document_.url === document.url);
      if (index > -1) this.documents.splice(index, 1);
    },
    requestUploadFile(){
      const src = this.$refs.inputUploadFile;
      this.addFiles(src.files);
    },
    addFiles(elements) {
      elements = [...elements];
      elements.sort((e1, e2) => e1.name.localeCompare(e2.name))
      elements.forEach(element => {
          element.url = this.createLocalDownloadUrl(element);
          this.documents.push({
            directusId: null,
            repeat: 1,
            url: element.url,
            file: element,
          });
        }
      );
      this.emitChanges();
    },
    emitChanges() {
      this.$emit('change', this.documents);
    },
    onDrop(e) {
      this.dragover = false;
      this.addFiles(e.dataTransfer.files);
    },
  }
}
</script>

<style scoped>
.rotate-180 {
  -moz-transform:rotate(-180deg);
  -webkit-transform:rotate(-180deg);
  transform:rotate(-180deg);
}

.animate-progress {
  transition:all 0.1s ease;
}

/deep/ .excluded-row {
  opacity: 0.5 !important;
}
</style>