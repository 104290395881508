<template>
  <div>
    <v-card-text class="pb-0 text--secondary">
      Extra
    </v-card-text>
    <v-card-title class="pt-0">
      {{ getTranslation(extraProduct.translations, 'name') }}
    </v-card-title>
    <v-card-subtitle class="pt-1 pb-0">
      <span v-if="extraProduct.defaultPrice.pricePrefix != null">
        {{ $t(extraProduct.defaultPrice.pricePrefix) }}
      </span>
      <span
        class="text--primary darken-2 font-weight-bold text-h5"
        v-html="extraProduct.defaultPrice.unitPrice"
      />
      <span class="text--primary darken-2 font-weight-bold pl-1">
        €
      </span>
      <span v-if="extraProduct.defaultPrice.priceSuffix != null">
        {{ $t(extraProduct.defaultPrice.priceSuffix) }}
      </span>
    </v-card-subtitle>
    <v-card-text
      v-if="available != null"
      class="text--secondary py-0"
    >
      {{ available }} {{ $t('available') }}
    </v-card-text>
    <v-card-text
      class="pt-2"
      v-html="getTranslation(extraProduct.translations, 'description')"
    />
  </div>
</template>

<script>
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "ExtraCard",
  mixins: [translation_mixin],
  props: {
    extraProduct: { type: Object, required: true, default: null },
    available: { type: Number, required: false, default: null }
  },
}
</script>

<style scoped>
</style>