<template>
  <div>
    <v-card-text
      v-if="feature.type === 'number'"
      class="py-1 d-flex align-baseline"
    >
      <div
        class="text-subtitle-1 text-center"
        style="width: 24px;"
        :style="{'color': feature.value > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"
        v-text="feature.value"
      />
      {{ description }}
    </v-card-text>

    <v-card-text
      v-else-if="feature.type === 'boolean'"
      class="py-1 align-baseline d-flex flex-col"
    >
      <v-icon
        small
        style="width: 24px;"
        :color="feature.value === 'true' ? 'primary' : 'error'"
        v-text="feature.value === 'true' ? 'mdi-check' : 'mdi-close'"
      />
      {{ description }}
    </v-card-text>

    <div v-else-if="feature.type === 'text'">
      <v-card-text
        v-if="description !== ''"
        class="py-1 pl-6 d-flex align-baseline"
      >
        {{ description }}
      </v-card-text>
      <v-card-text class="py-1 pl-6 d-flex align-baseline">
        {{ getTranslationFromRepeater(feature, "value_") }}
      </v-card-text>
    </div>

    <div v-else-if="feature.type === 'divider'">
      <v-divider class="mx-4" />
      <v-card-text class="pt-3 pb-1 d-flex align-baseline">
        {{ description }}
      </v-card-text>
    </div>
  </div>
</template>


<script>
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "FeatureBullet",
  mixins: [translation_mixin],
  props: {
    feature: { type: Object, required: true, default: null },
  },
  computed: {
    description() {
      return this.getTranslationFromRepeater(this.feature, "text_", null, "");
    },
  },
}
</script>


<style scoped>

</style>